<template>
  <div class="section top">
    <div class="company-name d-flex flex-column">
      <img class="top-logo" src="@/static/logo.png" alt="会社" />
      <h3>日本を代表するセキュリティカンパニーを目指して</h3>
    </div>
    <video
      class="topmov"
      src="@/static/upf-top.mp4"
      autoplay
      loop
      muted
      playsinline
    ></video>
  </div>
</template>

<script>
export default {
  name: "TopSection",
};
</script>

<style scoped>
.top {
  height: 100vh;
  position: relative;
  overflow: hidden;
}

.topmov {
  position: absolute;
  min-width: 100vw;
  min-height: 100vh;
}

.top-logo {
  width: 13%;
}

.company-name {
  color: #fff;
  position: absolute;
  z-index: 1;
  padding: 40px 100px 40px 40px;
}

.company-name h3 {
  font-size: 35px;
  margin-top: 50px;
  margin-left: 30px;
  color: #fff;
}

@media screen and (max-width: 1000px) {
  .top-logo {
    width: 20%;
  }

  .company-name {
    color: #fff;
    position: absolute;
    z-index: 1;
    padding: 70px 100px 40px 40px;
  }

  .company-name h3 {
    font-size: 25px;
  }
}

@media screen and (max-width: 600px) {
  .company-name h3 {
    font-size: 20px;
  }
}
</style>
