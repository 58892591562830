<template>
  <div>
    <header
      class="menu navbar navbar-expand-lg"
      :class="{ 'change-color': !isTopPage }"
    >
      <div class="container-fluid">
        <a
          href="#/"
          class="header-logo navbar-brand"
          :class="{ 'header-logo-active': !isTopPage }"
          ><img src="@/static/logo.png" alt="UPFlogo"
        /></a>
        <button
          class="navbar-toggler"
          type="button"
          data-bs-toggle="collapse"
          data-bs-target="#navbarNavDropdown"
          aria-controls="navbarNavDropdown"
          aria-expanded="false"
          aria-label="Toggle navigation"
        >
          <span class="navbar-toggler-icon"></span>
        </button>
      </div>
      <div
        class="menu-container collapse navbar-collapse"
        id="navbarNavDropdown"
      >
        <ul class="navbar-nav">
          <li class="nav-item dropdown">
            <a
              class="menu-list nav-link dropdown-toggle"
              href="#/servicelist"
              role="button"
              aria-expanded="false"
            >
              Service
            </a>
            <ul
              class="dropdown-menu align-middle"
              aria-labelledby="dropdownMenuButton1"
            >
              <li class="menu-detail dropdown-item">
                <a href="#/servicelist" class="menu-link">サービス一覧</a>
              </li>
              <li class="menu-detail dropdown-item">
                <a href="#/comingsoon" class="menu-link">お客様事例</a>
              </li>
            </ul>
          </li>
          <li class="nav-item dropdown">
            <a
              class="menu-list nav-link dropdown-toggle"
              href="#/company"
              role="button"
              aria-expanded="false"
            >
              Company
            </a>
            <ul class="dropdown-menu" aria-labelledby="dropdownMenuButton1">
              <li class="menu-detail dropdown-item">
                <a href="#/company#company-profile" class="menu-link"
                  >会社概要</a
                >
              </li>
              <li class="menu-detail dropdown-item">
                <a href="#/company#greetings-president" class="menu-link"
                  >代表挨拶</a
                >
              </li>
              <li class="menu-detail dropdown-item">
                <a href="#/brandmessage" class="menu-link"
                  >ブランドメッセージ</a
                >
              </li>
              <li class="menu-detail dropdown-item">
                <a href="#/#news-section" class="menu-link">ニュース</a>
              </li>
            </ul>
          </li>
          <li class="nav-item dropdown">
            <a
              class="menu-list nav-link dropdown-toggle"
              href="#/Recruit"
              role="button"
              aria-expanded="false"
            >
              Recruit
            </a>
            <ul class="dropdown-menu" aria-labelledby="dropdownMenuButton1">
              <li class="menu-detail dropdown-item">
                <a href="#/People" class="menu-link">社員紹介</a>
              </li>
              <li class="menu-detail dropdown-item">
                <a href="#/Recruit" class="menu-link">採用情報</a>
              </li>
              <li class="menu-detail dropdown-item">
                <a href="#/environment" class="menu-link">社内環境・制度</a>
              </li>
            </ul>
          </li>
          <li class="nav-item dropdown">
            <a href="#/comingsoon" class="menu-list nav-link">Seminar</a>
          </li>
          <li class="nav-item dropdown">
            <a href="#/Contact" class="menu-list nav-link">Contact</a>
          </li>
        </ul>
      </div>
    </header>
    <div class="blackout"></div>
  </div>
</template>

<script>
export default {
  name: "UpfHeader",
  props: {
    msg: String,
  },
  data() {
    return {
      isTopPage: this.$route.path === "/" ? true : false,
    };
  },
  watch: {
    $route(to) {
      this.isTopPage = to.name === "Home";
    },
  },
  mounted() {
    $(".dropdown").hover(
      function () {
        if (window.innerWidth >= 992) $(".blackout").addClass("show");
      },
      function () {
        if (window.innerWidth >= 992) $(".blackout").removeClass("show");
      }
    );
  },
};

jQuery(window).on("scroll", function () {
  const currentLocation =
    location.href.split("/")[location.href.split("/").length - 1];

  // homeのときだけスクロールでヘッダーの背景色を変える
  if (currentLocation === "" || currentLocation.startsWith("#")) {
    if ($(window).height() <= jQuery(this).scrollTop()) {
      jQuery(".menu").addClass("change-color");
      jQuery(".header-logo").addClass("header-logo-active");
    } else {
      jQuery(".menu").removeClass("change-color");
      jQuery(".header-logo").removeClass("header-logo-active");
    }
  }
});
</script>

<style scoped>
.menu {
  position: fixed;
  display: flex;
  height: 80px;
  z-index: 1001;
  transition: all 1s;
  width: 100%;
}

.header-logo {
  display: flex;
  visibility: hidden;
  align-items: center;
}

.header-logo img {
  width: 6rem;
  padding-left: 10px;
}

.header-logo-active {
  visibility: visible;
}

.change-color {
  background-color: rgba(255, 255, 255, 0.4);
  .menu-list {
    color: #000;
  }
  .menu-list:hover {
    color: #0366c2;
  }
}

.menu > div {
  padding: 20px;
}

.navbar-toggler {
  border: none;
}

.menu-list {
  font-family: "Cormorant Garamond", serif;
  font-weight: bold;
  font-size: 32px;
  padding: 30px 10px 30px 10px;
  text-align: center;
}

.menu-list:hover,
.menu-link:hover {
  color: #0366c2;
}

@media (min-width: 992px) {
  .dropdown {
    pointer-events: auto;
  }
  .dropdown > a {
    color: #fff;
  }
  .dropdown-menu,
  .menu-link {
    width: 240px;
  }

  .dropdown:hover .dropdown-menu {
    display: block;
    margin-top: 0;
    left: -30;
  }

  .dropdown-toggle::after {
    display: none;
  }

  .menu-container {
    width: 100%;
    display: flex;
    justify-content: right;
    align-items: center;
  }
}

.menu-detail {
  list-style-type: disc;
  display: block;
  line-height: 40px;
  padding-left: 40px;
}

.menu-link {
  color: #000;
  font-size: 19px;
}

.blackout {
  position: fixed;
  background: rgba(0, 0, 0, 0.5);
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  z-index: 1000;

  /* 初期状態 */
  opacity: 0;
  visibility: hidden;
  transition: opacity 0.5s ease-in-out, visibility 0s 0.5s; /* visibilityの遅延 */
}

.blackout.show {
  opacity: 1;
  visibility: visible;
  transition: opacity 0.5s ease-in-out; /* visibilityの遅延をリセット */
}

@media screen and (max-width: 991px) {
  .menu-container {
    width: 100px;
  }
  .nav-link {
    font-size: 14px;
    margin-top: 20px;
    margin-bottom: 20px;
    padding: 0;
  }
  .menu-link {
    width: 100px;
  }
  .menu-detail {
    margin: 0;
    padding: 0;
  }
  .nav-item {
    width: 150px;
  }
  .navbar-nav {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    background-color: #fff;
  }

  .dropdown-menu {
    margin: 0;
    padding: 0;
    display: block !important; /* 常に展開 */
    position: static !important; /* デフォルトの絶対配置を解除 */
    box-shadow: none; /* 影をなくす（任意） */
    background: transparent; /* 背景色を調整（任意） */
    border: none;
    & a {
      margin: 0;
      padding: 0;
      font-size: 11px;
    }
  }

  .dropdown-item {
    margin-left: 50px;
    padding: 0;
  }

  .dropdown-toggle::after {
    content: none !important; /* 下三角を削除 */
  }
}
</style>
