<template>
  <div>
    <div class="servicelist-heading">
      <h1>サービス一覧</h1>
    </div>
    <div class="servicelist">
      <div v-for="(service, index) in services" :key="index">
        <CardWithThumb
          :title="service.title"
          :image-url="service.imageUrl"
          :description="service.description"
        >
          <RoundedButton :url="service.url" :text="'サービスサイトへ'" />
        </CardWithThumb>
      </div>
    </div>
  </div>
</template>

<script>
import servicesData from "@/assets/services.json";
import CardWithThumb from "@/components/atoms/CardWithThumb.vue";
import RoundedButton from "@/components/atoms/RoundedButton.vue";

export default {
  name: "ServiceListPage",
  components: { CardWithThumb, RoundedButton },
  data() {
    return {
      services: servicesData,
    };
  },
};
</script>

<style scoped>
::v-deep(.card-box) {
  display: block !important;
  width: 400px;
}

.servicelist-heading {
  height: 450px;
  display: flex;
  align-items: center;
  padding-left: 6%;
  padding-top: 8%;
  border-bottom: 1px solid #e2e1e1;

  & h1 {
    font-size: clamp(18px, 50px, 70px);
  }
}

.servicelist {
  display: flex;
  justify-content: space-around;
  flex-wrap: wrap;
  margin-top: 100px;
  margin-bottom: 200px;
}
@media screen and (max-width: 1200px) {
  ::v-deep(.card-box) {
    height: 60vh;
  }
  ::v-deep(.news-links-container) {
    margin: auto 0px auto auto;
  }
  ::v-deep(.news-links) {
    width: 50vw;
    margin-left: 100px;
  }

  ::v-deep(.news-link) {
    font-size: 15px;
  }

  ::v-deep(.card-description) {
    height: 20vh;
    font-size: 19px;
  }

  ::v-deep(.card-box > h6) {
    font-size: 22px;
  }

  ::v-deep(.button-rounded) {
    border-radius: 30px;
    background-color: #00417f;
    color: #fff !important;
    padding: 10px 20px;
    margin-left: 22px;
    font-size: 14px;
    transition: color 0.2s, background-color 0.2s;
  }

  ::v-deep(.button-rounded:hover) {
    font-size: 14px;
    color: #000 !important;
    cursor: pointer;
    background-color: #fff;
    border: 1px solid #00417f;
  }
}

@media screen and (max-width: 800px) {
  ::v-deep(.card-box) {
    height: 65vh;
  }
  ::v-deep(.news-links) {
    margin-left: 50px;
    width: 60vw;
  }
  ::v-deep(.news-link) {
    font-size: 15px;
  }
  ::v-deep(.card-description) {
    height: 20vh;
    font-size: 19px;
  }

  ::v-deep(.card-box > h6) {
    font-size: 22px;
  }

  ::v-deep(.button-rounded) {
    border-radius: 30px;
    background-color: #00417f;
    color: #fff !important;
    padding: 10px 20px;
    margin-left: 22px;
    font-size: 14px;
    transition: color 0.2s, background-color 0.2s;
  }

  ::v-deep(.button-rounded:hover) {
    font-size: 14px;
    color: #000 !important;
    cursor: pointer;
    background-color: #fff;
    border: 1px solid #00417f;
  }
}
@media screen and (max-width: 650px) {
  .servicelist-heading {
    & h1 {
      font-size: 40px;
    }
  }
  ::v-deep(.card-box) {
    width: 300px;
    height: 620px;
  }
  ::v-deep(.news-links-container) {
    margin: auto;
    width: 80%;
    border: 1px solid #da1616;
  }
  ::v-deep(.news-links) {
    margin-left: 50px;
    width: 60vw;
  }
  ::v-deep(.news-link) {
    font-size: 15px;
  }
  ::v-deep(.card-description) {
    height: 260px;
    font-size: 19px;
  }

  ::v-deep(.card-box > h6) {
    font-size: 22px;
  }

  ::v-deep(.button-rounded) {
    border-radius: 30px;
    background-color: #00417f;
    color: #fff !important;
    padding: 10px 20px;
    margin-left: 22px;
    font-size: 14px;
    transition: color 0.2s, background-color 0.2s;
  }

  ::v-deep(.button-rounded:hover) {
    font-size: 14px;
    color: #000 !important;
    cursor: pointer;
    background-color: #fff;
    border: 1px solid #00417f;
  }
  ::v-deep(.card-description) {
    margin: 10px 25px;
  }
  ::v-deep(.card-img) {
    height: 25vh;
  }
}
</style>
