<template>
  <div class="mb-4 news-item">
    <p class="news-date">{{ date }}</p>
    <div class="news-link-container d-flex justify-content-between">
      <a :href="link" class="news-link">{{ title }}</a>
      <i class="bi bi-arrow-right-short mx-3"></i>
    </div>
  </div>
</template>

<script>
export default {
  name: "NewsItem",
  props: {
    date: {
      type: String,
      required: true,
    },
    title: {
      type: String,
      required: true,
    },
    link: {
      type: String,
      required: true,
    },
  },
};
</script>

<style scoped>
.news-date {
  width: 50%;
  padding: 0;
  margin: 0;
  font-size: 14px;
}

.news-link-container {
  width: 100%;
  border-bottom: 1px solid #c0c0c0;

  & i {
    transition: all 1s;
  }
}

.news-link-container:hover {
  opacity: 0.5;
  & i {
    transform: translateX(10px);
  }
}

.news-item {
  width: 100%;
}

.news-link {
  font-size: 15px;
  color: #000;
}

@media screen and (max-width: 1000px) {
}

@media screen and (max-width: 800px) {
  .news-link {
    font-size: 13px;
  }
}
@media screen and (max-width: 600px) {
  .news-link {
    font-size: 13px;
  }
}
</style>
