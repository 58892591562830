<template>
  <div>
    <div class="top-contact-heading">
      <h1>送信内容の確認</h1>
    </div>

    <div v-if="formData">
      <div class="contactform-containr">
        <p class="contactform-text">
          送信内容を確認の上、「送信」ボタンを押してください。
        </p>

        <form @submit.prevent="submitForm">
          <FormItem
            v-for="item in displayItems"
            :key="item.key"
            :name="item.label"
            class="mb-3"
          >
            <template v-if="item.key === 'freeSpace'">
              <pre style="white-space: pre-wrap">{{ formData[item.key] }}</pre>
            </template>

            <template v-else-if="item.key === 'consent'">
              <span>
                {{
                  formData[item.key] === "true" || formData[item.key] === true
                    ? "同意済み"
                    : "未同意"
                }}
              </span>
            </template>

            <template v-else-if="item.key === 'file'">
              <span v-if="formData.fileName">{{ formData.fileName }}</span>
              <span v-else>（ファイル未指定）</span>
            </template>

            <template v-else>
              {{
                item.transform
                  ? item.transform(formData[item.key])
                  : formData[item.key]
              }}
            </template>
          </FormItem>
        </form>
      </div>

      <!-- メッセージ表示 -->
      <div class="sent-message-container deactive">
        <p class="sent-message">
          送信しました。<br />
          お問い合わせありがとうございます。<br />
          3秒後にトップページへ自動的に移動します。
        </p>
      </div>

      <div class="error-message-container deactive">
        <p class="error-message">
          送信に失敗しました。<br />
          もう一度お試しください。
        </p>
      </div>

      <div class="actions">
        <button class="browser-back" @click="goBack">戻る</button>
        <button class="send" @click="submitForm">送信する</button>
      </div>
    </div>

    <div v-else>
      <p>フォームデータが見つかりません。</p>
      <button @click="$router.push('/')">入力画面に戻る</button>
    </div>
  </div>
</template>

<script>
import FormItem from "../atoms/FormItem.vue";
import axios from "axios";
import { getUploadedFiles } from "../utils/uploadedFile";

export default {
  components: {
    FormItem,
  },
  computed: {
    formData() {
      const data = { ...this.$route.query };
      data["fileName"] =
        data["fileName"] || sessionStorage.getItem("uploadedFileName");

      console.log(data["fileName"]);
      if (data["fileName"] === null) {
        data["fileName"] = "";
      }

      for (const key in data) {
        data[key] = decodeURIComponent(data[key]);
      }

      return data;
    },
    displayItems() {
      return [
        { key: "name", label: "お名前" },
        { key: "furiganaName", label: "ふりがな" },
        {
          key: "genderType",
          label: "性別",
          transform: (v) => {
            switch (v) {
              case "man":
                return "男性";
              case "woman":
                return "女性";
              case "other":
                return "その他";
              default:
                return "";
            }
          },
        },
        { key: "birthdate", label: "生年月日" },
        { key: "phoneNumber", label: "電話番号" },
        { key: "email", label: "メールアドレス" },
        { key: "postcode", label: "郵便番号" },
        { key: "residence", label: "ご住所" },
        {
          key: "inquiryType",
          label: "希望職種",
          transform: (v) => {
            switch (v) {
              case "consultant":
                return "コンサルタント";
              case "sales":
                return "セールス";
              case "backOffice":
                return "バックオフィス";
              default:
                return "";
            }
          },
        },
        { key: "schoolName", label: "卒業学校名" },
        { key: "faculty", label: "学部・学科" },
        { key: "qualification", label: "保有資格" },
        { key: "freeSpace", label: "ご質問・自己PRなど" },
        { key: "file", label: "添付ファイル" },
        { key: "consent", label: "個人情報の取り扱い" },
      ];
    },
  },
  methods: {
    async submitForm() {
      try {
        const formDataToSend = new FormData();
        for (const key in this.formData) {
          formDataToSend.append(key, this.formData[key]);
        }

        const files = getUploadedFiles();
        if (files && files.length > 0) {
          files.forEach((file) => {
            formDataToSend.append("file", file, file.name);
          });
        }

        const postUrl =
          this.$route.query.from == "/midcareerHiring"
            ? "mid-career-entry"
            : "new-graduate-entry";

        const response = await axios.post(
          "https://upfgroup.co.jp/backend/index.cgi/" + postUrl,
          // "http://localhost:5000/" + postUrl,
          formDataToSend
        );

        const sleep = (time) => new Promise((r) => setTimeout(r, time));

        if (response.status === 200) {
          $(".actions").addClass("deactive");
          $(".sent-message-container")
            .removeClass("deactive")
            .addClass("active");
          $(".error-message-container")
            .removeClass("active")
            .addClass("deactive");
          await sleep(3000);
          this.$router.push("/");
        } else {
          $(".error-message-container").addClass("active");
          $(".error-message-container").removeClass("deactive");
        }
      } catch (error) {
        console.error("送信エラー:", error);
        $(".error-message-container").removeClass("deactive");
        $(".error-message-container").addClass("active");
      }
    },

    goBack() {
      console.log(this.$route.query.from);
      const fromPath = this.$route.query.from || "/Recruit";
      this.$router.push({
        path: fromPath,
        query: this.formData,
      });
    },
  },
};
</script>

<style scoped>
.deactive {
  display: none !important;
}

.active {
  display: block;
}

.sent-message {
  text-align: center;
  margin-bottom: 90px;
}

.error-message {
  color: red;
  text-align: center;
}

.top-contact-heading {
  height: 300px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-bottom: 1px solid #e2e1e1;

  & h1 {
    font-size: clamp(20px, 50px, 70px);
  }
}

.contactform-containr {
  margin: 10% auto;
  min-width: 9rem;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.contactform-text {
  width: 70%;
  font-size: 13px;
  padding-bottom: 40px;
  border-bottom: 1px solid #e2e1e1;
}

form {
  width: 70%;
  margin-top: 50px;
}

a {
  color: #000;
}

.actions {
  display: flex;
  justify-content: center;
  margin-bottom: 200px;
}

.browser-back,
.send {
  display: block;
  width: 30%;
  height: 80px;
  margin: auto;
  font-size: 14px;
  color: #fff;
  background-color: #00417f;
  margin-top: 40px;
}

.browser-back:hover,
.send:hover {
  background-color: #00274d;
}

::v-deep(.form-item-name) {
  width: 300px;
}
</style>
