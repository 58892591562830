<template>
  <footer>
    <div class="footer-menu-list">
      <div class="footer-logo-box">
        <img class="footer-logo" src="@/static/logo.png" alt="ロゴ" />
      </div>
      <div class="footer-menu-box">
        <div class="footer-menu-content">
          <div class="footer-memu footer-service">
            <h6 class="footer-menu-overview">
              <a href="#/servicelist" class="footer-menu-links">Service</a>
            </h6>
            <ul class="">
              <li class="footer-menu-detail">
                <a href="#/servicelist" class="footer-menu-link"
                  >・サービス一覧</a
                >
              </li>
              <li class="footer-menu-detail">
                <a href="#/comingsoon" class="footer-menu-link">・お客様事例</a>
              </li>
            </ul>
          </div>
          <div class="footer-memu">
            <h6 class="footer-menu-overview">
              <a href="#/company" class="footer-menu-links">Company</a>
            </h6>
            <ul class="">
              <li class="footer-menu-detail">
                <a href="#/company#company-profile" class="footer-menu-link"
                  >・会社概要</a
                >
              </li>
              <li class="footer-menu-detail">
                <a href="#/company#greetings-president" class="footer-menu-link"
                  >・代表挨拶</a
                >
              </li>
              <li class="footer-menu-detail">
                <a href="#/brandmessage" class="footer-menu-link"
                  >・ブランドメッセージ</a
                >
              </li>
              <li class="footer-menu-detail">
                <a href="#/#news-section" class="footer-menu-link"
                  >・ニュース</a
                >
              </li>
            </ul>
          </div>
          <div class="footer-memu">
            <h6 class="footer-menu-overview">
              <a href="#/Recruit" class="footer-menu-links">Recruit</a>
            </h6>
            <ul class="footer-menu-container">
              <li class="footer-menu-detail">
                <a href="#/People" class="footer-menu-link">・社員紹介</a>
              </li>
              <li class="footer-menu-detail">
                <a href="#/Recruit" class="footer-menu-link">・採用情報</a>
              </li>
              <li class="footer-menu-detail">
                <a href="#/environment" class="footer-menu-link"
                  >・社内環境・制度</a
                >
              </li>
            </ul>
          </div>
          <a href="#/comingsoon" class="footer-memu">
            <h6 class="footer-menu-links">Seminar</h6>
          </a>

          <a href="#/Contact" class="footer-memu">
            <h6 class="footer-menu-links">Contact</h6>
          </a>
        </div>
        <div class="footer-links">
          <div class="icons d-flex justify-content-between">
            <div class="sns-icon mt-4">
              <a href="https://www.facebook.com/pmark.jp/" target="_blank"
                ><img src="@/static/Facebook_Logo_Secondary.png" alt="Facebook"
              /></a>
              <a href="https://twitter.com/upf_privacymark" target="_blank"
                ><img src="@/static/logo-white.png" alt="x"
              /></a>
              <a href="https://www.instagram.com/upf_staff/" target="_blank"
                ><img src="@/static/Instagram_Glyph_White.png" alt="Instagram"
              /></a>
              <a
                href="https://www.youtube.com/channel/UC8QHwh4M2TKb48ODLS5Ik-g"
                target="_blank"
                class="youtube"
                ><img src="@/static/yt_logo_mono_dark.png" alt="youtube"
              /></a>
            </div>
            <div class="service-icon mt-4">
              <img class="isms-mark" src="@/static/isms1.jpg" alt="isms" />
              <img class="isms-mark" src="@/static/isms2.jpg" alt="isms" />
              <img
                class="privacy-mark"
                src="@/static/privacy-mark.jpg"
                alt="プライバシーマーク"
              />
            </div>
          </div>

          <div class="policy-footer">
            <div class="policy d-flex">
              <a href="#/personalinformationpolicy" class="mx-2"
                >個人情報保護方針</a
              >
              <a href="#/informationsecuritypolicy" class="mx-2"
                >情報セキュリティ方針</a
              >
              <a href="#/antisocialpolicy" class="mx-2"
                >反社会的勢力に対する基本方針</a
              >
            </div>
            <div class="copyright"><p>©2025 UPF</p></div>
          </div>
        </div>
      </div>
    </div>
  </footer>
</template>

<script>
export default {
  name: "UpfFooter",
};
</script>

<style scoped>
.footer-menu-list {
  display: flex;
}

.footer-logo-box {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 20%;
  float: left;
  background-color: #080d20;
}

.footer-logo {
  width: 200px;
}

.footer-menu-box {
  padding-top: 5vh;
  width: 80%;
  float: left;
  margin: auto;
  color: #fff;
  background-color: #1a275e;
}

.footer-memu {
  width: 150px;
  margin: 30px 20px;
  left: 0;
}

.footer-menu-content {
  width: 100%;
  display: flex;
  justify-content: center;
}

.footer-menu-links {
  color: #fff;
  font-size: 17px;
  font-family: "Cormorant Garamond", serif;
}

.footer-menu-link {
  font-size: 13px;
  color: #fff;
}

.footer-menu-link:hover,
.footer-menu-links:hover {
  opacity: 0.5;
}

.footer-memu > ul {
  margin: 0;
  padding: 0;
}

.footer-menu-detail {
  list-style: none;
  font-size: 19px;
  line-height: 30px;
  margin: auto;
}

.footer-links {
  width: 70%;
  margin: auto;
  margin-top: 45px;
  border-top: 0.5px solid #4d6485;
}

.sns-icon img {
  width: 1em;
  margin: 10px;
}

.sns-icon > .youtube img {
  width: 4em;
}

.isms-mark {
  width: 4em;
  margin: 2px;
}

.privacy-mark {
  width: 3em;
  margin: 7px;
}

.policy-footer {
  display: flex;
  justify-content: space-between;
  margin-top: 30px;
}

.policy a {
  color: #fff;
  font-size: 14px;
  margin-bottom: 0.5rem;
}

@media screen and (max-width: 1200px) {
  .footer-menu-list {
    flex-direction: column;
  }

  .footer-logo-box {
    width: 100%;
    height: 200px;
  }

  .footer-logo {
    width: 200px;
  }

  .footer-menu-box {
    width: 100%;
    padding-top: 100px;
    margin: auto;
  }
  .footer-memu {
    margin: 30px 10px;
  }

  .footer-menu-content {
    padding-left: 3%;
  }

  .footer-links {
    width: 90%;
  }
}

@media screen and (max-width: 700px) {
  .footer-menu-box {
    width: 100%;
    padding-top: 100px;
  }

  .footer-menu-content {
    width: 85%;
    margin-left: 5%;
    justify-content: flex-start;
    flex-direction: row;
    flex-wrap: wrap;
  }

  .footer-memu {
    width: 40%;
    margin-bottom: 50px;
  }
  .footer-links {
    width: 85%;
  }

  .policy-footer {
    flex-wrap: wrap;
  }

  .policy {
    flex-wrap: wrap;
  }

  .policy > a {
    width: 100%;
  }

  .copyright {
    padding-top: 20px;
    margin: auto;
    opacity: 0.7;
  }
}
</style>
