<template>
  <div>
    <div class="top-securitypolicy-heading">
      <h1>情報セキュリティ方針</h1>
    </div>
    <div class="securitypolicy-container">
      <div>
        <h4>1 目的</h4>
        <p>
          株式会社UPF（以下、「当社」といいます）は、情報セキュリティに関するコンサルティング事業、並びに従業者の管理（以下、「事業」といいます）を実施するに当たり、多くの情報資産を利用していることから、情報セキュリティを適切に実現し、情報資産の保護に努めることは、社会の信頼のもとに企業活動を推進するための必要不可欠な要件であるとともに、重大な社会的責務であると認識しております。よって、当社は情報セキュリティの重要性を鑑み、この情報セキュリティ方針（以下、「本方針」といいます）を定め、具体的に実施するための情報セキュリティマネジメントシステムを確立し、実施し、維持し、且つ改善してまいります。
        </p>
      </div>
      <div>
        <h4>2 情報セキュリティの定義</h4>
        <p>
          情報セキュリティとは、機密性、完全性及び可用性を維持することと定義しています。
        </p>
        <p>(1) 機密性</p>
        <p>
          情報資産を不正アクセスなどから保護し、参照する権限のないものに漏洩しないことを意味します。
          （認可されていない個人、エンティティ又はプロセスに対して、情報を使用させず、また、開示しない特性）
        </p>
        <p>(2) 完全性</p>
        <p>
          情報資産を改竄や間違いから保護し、正確かつ完全に維持されることを意味します。
          （正確さ及び完全さの特性
        </p>
        <p>(3) 可用性</p>
        <p>
          情報資産を紛失・破損やシステムの停止などから保護し、必要なときに利用できることを意味します。
          （認可されたエンティティが要求したときに、アクセス及び使用が可能である特性）
        </p>
      </div>
      <div>
        <h4>3 適用範囲</h4>
        <p>
          本方針を当社の管理する情報資産の全てに対して適用します。情報資産の範囲は、電子的機器並びに電子データにとどまらず、紙媒体を含めた全ての形態を含みます。
        </p>
        <p>(1) 組織</p>
        <p>株式会社UPF</p>
        <p>(2) 施設</p>
        <p>住所:〒103-0001 東京都中央区日本橋小伝馬町2-4 三報ビルディング5階</p>
        <p>(3) 業務</p>
        <p>情報セキュリティコンサルティング業務</p>
        <p>(4) 資産</p>
        <P
          >上記業務、各種サービスにかかわる書類、データ、情報システム及びネットワーク</P
        >
      </div>
      <div>
        <h4>4 実施事項</h4>
        <p>
          本方針並びに当社の情報セキュリティマネジメントシステムに従い、下記の事項を実施します。
        </p>
        <p>(1) 情報セキュリティ目的について</p>
        <p>
          本方針と整合性を有し、適用される情報セキュリティ要求事項、並びにリスクアセスメント及びリスク対応の結果を考慮した情報セキュリティ目的を策定し、全従業者に周知するとともに、当社の環境の変化に応じて随時、変化がなくとも定期的な見直しを行います。
        </p>
        <p>(2) 情報資産の取り扱いについて</p>
        <p>a) アクセス権限は、業務上必要な者のみに与えることとします。</p>
        <p>
          b)
          法的及び規制の要求事項並びに契約上の要求事項、当社の情報セキュリティマネジメントシステムの規定に従い管理を行います。
        </p>
        <p>
          c)
          報資産の価値、機密性、完全性、可用性の観点から、それらの重要性に応じて適切に分類し管理を行います。
        </p>
        <p>
          d)
          情報資産が適切に管理されていることを確認するために、継続的に監視を実施します。
        </p>
        <p>(3) リスクアセスメントについて</p>
        <p>
          a)
          リスクアセスメントを行い、事業の特性から最も重要と判断する情報資産について適切なリスク対応を実施し、管理策を導入します。
        </p>

        <p>
          b) 情報セキュリティに関連する事故原因を分析し、再発防止策を講じます。
        </p>
        <p>(4) 事業継続管理について</p>
        <p>
          災害や故障などによる事業の中断を最小限に抑え、事業継続能力を確保します。
        </p>
        <p>(5) 教育について</p>
        <p>全従業者に対し、情報セキュリティ教育および訓練を実施します。</p>
        <p>(6) 規定並びに手順の順守</p>
        <p>
          情報セキュリティマネジメントシステムの規定並びに手順を順守します。
        </p>
        <p>(7) 法的及び規制の要求事項並びに契約上の要求事項の順守</p>
        <p>
          情報セキュリティに関する法的及び規制の要求事項並びに契約上の要求事項を順守します。
        </p>
        <p>(8) 継続的改善</p>
        <p>
          情報セキュリティマネジメントシステムの継続的な改善に取り組みます。
        </p>
      </div>
      <div>
        <h4>5 責任と義務及び罰則</h4>
        <p>
          本方針を含めた情報セキュリティマネジメントシステムに対する責任は代表取締役が負うこととし、適用範囲の従業者は策定された規定や手順を順守する義務を負うものとします。なお義務を怠り、違反行為を行った従業者は就業規則に定めるところにより処分します。協力会社社員については個別に定めた契約などに従って、対応を行います。
        </p>
      </div>
      <div>
        <h4>6 定期的見直し</h4>
        <p>
          情報セキュリティマネジメントシステムの見直しは、定期的および必要に応じてレビューし、維持・管理するものとします。
        </p>
      </div>
      <div class="date-enactment-securitypolicy">
        <p>制定:2019年7月1日</p>
        <p>最終改定日:2022年7月7日</p>
        <p>代表取締役 仲手川 啓</p>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "InformationSecurityPolicyPage",
  components: {},
};
</script>

<style scoped>
h1 {
  height: 350px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 40px;
  text-align: center;
}

.securitypolicy-container {
  width: 70%;
  margin: auto;
  margin-bottom: 200px;
}

.securitypolicy-container div {
  margin-bottom: 50px;
}
h4 {
  font-size: 18px;
  margin-bottom: 20px;
}
p {
  font-size: 14px;
}

.date-enactment-securitypolicy {
  margin-top: 100px;
  text-align: right;
}
</style>
