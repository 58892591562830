<template>
  <div>
    <div class="information-security-heading">
      <h1>Information Security</h1>
    </div>
    <img
      class="information-security-img"
      src="@/static/Information-Security.jpg"
      alt=""
    />

    <div class="information-security-strategy">
      <h2>Strategy</h2>
      <div class="strategy-little-explanation">
        <div>
          <b>「情報セキュリティコンサルティング」</b>
          <p>
            情報セキュリティに関するプロフェッショナルとして、情報の価値を守るための包括的ソリューションをご提案します
          </p>
        </div>
      </div>
    </div>
    <div class="security-container">
      <CardWithCircleIcon
        :imageUrl="require('@/static/protection.png')"
        title="Protection"
        subtitle="信頼を構築し、企業の未来を守る"
        description="実際の運用に即した形で、セキュリティ対策を一から見直し、クライアントのビジネスニーズに最適化されたソリューションを提供します"
      />
      <CardWithCircleIcon
        :imageUrl="require('@/static/love.png')"
        title="Safety"
        subtitle="実践的なセキュリティ対策で業務の安心を提供"
        description="IT環境や業務内容の変化に応じて、情報セキュリティの強化は絶え間ない進化を要求されます。私たちは、企業の変革に伴う新たなリスクを予測し、それに対応できる柔軟で先進的なセキュリティ対策を提案します。"
      />
      <CardWithCircleIcon
        :imageUrl="require('@/static/trust.png')"
        title="Professional"
        subtitle="実務経験豊富なメンバーと共に成長を支える"
        description="実務経験豊富なメンバーが、常に最適なセキュリティ環境を維持し、企業の成長を支える強固な基盤を築きます。"
      />
    </div>

    <div class="servicemenu-container">
      <div>
        <h2>Service Menu</h2>
      </div>
      <div class="service-menu-box">
        <div class="service-item">
          <p>情報セキュリティ監査</p>
        </div>

        <div class="service-item">
          <p>情報セキュリティ研修</p>
        </div>
      </div>
      <div class="service-menu-box">
        <div class="service-item">
          <p>セキュリティ計画策定</p>
        </div>

        <div class="service-item">
          <p>セキュリティアセスメント</p>
        </div>
      </div>
    </div>

    <div class="services-link">
      <button class="riskmanagementservice-url">
        <a href="#/RiskManagementService">
          <h3 class="service-panel-title">Risk Management</h3>
          <p class="service-panel-subtitle">
            リスクマネジメントコンサルティング
          </p>
          <p class="service-panel-link">
            View More <i class="bi bi-arrow-right mx-3"></i>
          </p>
        </a>
      </button>
      <button class="certificationaccreditation-url">
        <a href="#/CertificationAccreditation">
          <h3 class="service-panel-title">Certification and Accreditation</h3>
          <p class="service-panel-subtitle">第三者認証取得支援</p>
          <p class="service-panel-link">
            View More <i class="bi bi-arrow-right mx-3"></i>
          </p>
        </a>
      </button>
    </div>
    <ContactSection />
  </div>
</template>

<script>
import ContactSection from "@/components/organisms/ContactSection.vue";
import CardWithCircleIcon from "@/components/atoms/CardWithCircleIcon.vue";

export default {
  name: "ServicePage",
  components: { ContactSection, CardWithCircleIcon },
};
</script>

<style scoped>
.services-link {
  border: 1px solid black;
}

.service-panel-title {
  height: 70px;
}

.information-security-heading {
  height: 450px;
  display: flex;
  align-items: center;
  padding-left: 6%;
  padding-top: 8%;
  font-family: "Cormorant Garamond", serif;

  & h1 {
    font-size: clamp(20px, 50px, 70px);
  }
}

.information-security-img {
  width: 100%;
  height: 800px;
  aspect-ratio: 16 / 7;
  background-size: cover;
  object-fit: cover;
  display: block;
}

.information-security-strategy {
  margin-top: 200px;
  margin-bottom: 150px;
  display: flex;
  justify-content: center;
}

.information-security-strategy h2 {
  font-size: 50px;
  margin: auto 10%;
}

.strategy-little-explanation {
  margin: auto 10%;
  & p {
    font-size: 13px;
  }
}

.security-container {
  margin-bottom: 250px;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
}

.security-box {
  width: 500px;
  height: 600px;
  margin: 10px;
  background-color: #00417f;
  color: #fff;
}

.security-box h5 {
  font-family: "Cormorant Garamond", serif;
  margin-top: 80px;
  margin-bottom: 60px;
  font-size: 22px;
  font-weight: bold;
  text-align: center;
}

.security-box h6 {
  text-align: center;
  margin-top: 50px;
}

.security-box img {
  width: 30%;
  padding: 30px;
  margin: auto;
  background-color: #ebebec;
  clip-path: circle(50%);
  display: block;
}

.security-box p {
  margin: 30px 60px;
  font-size: 13px;
}

.servicemenu-container {
  height: 770px;
  margin-bottom: 300px;
  background-color: aliceblue;

  & h2 {
    font-size: 50px;
    margin-left: 8%;
    margin-bottom: 100px;
    padding-top: 150px;
  }
}

.service-menu-box {
  display: flex;
  justify-content: space-around;
  margin-bottom: 70px;
}

.service-item {
  width: 35%;
  height: 100px;
  background-color: #00417f;

  color: #fff;
  font-size: 20px;
  padding-left: 15px;
  line-height: 100px;
}

.riskmanagementservice-url,
.certificationaccreditation-url {
  width: 50%;
  height: 500px;
  background-size: cover;
  object-fit: cover;
  border: none;

  & a {
    color: #fff;
  }
}

.riskmanagementservice-url {
  background-image: url(@/static/Risk-Management-short.jpg);
}

.certificationaccreditation-url {
  background-image: url(@/static/Certification-Accreditation-short.jpg);
}

.service-panel-link {
  margin-top: 10%;
  font-size: 80%;
  font-family: "Cormorant Garamond", serif;
  font-weight: 100;
  display: flex;
  margin-left: 43%;
  font-size: 15px;
}

.service-panel-link i {
  transition: transform 0.4s ease; /* 矢印アイコンの遷移設定 */
}

.riskmanagementservice-url:hover .service-panel-link i,
.certificationaccreditation-url:hover .service-panel-link i {
  transform: translateX(5px); /* 矢印アイコンを5px右へ移動 */
}

@media screen and (max-width: 1000px) {
  .information-security-strategy {
    flex-direction: column;
  }

  .information-security-strategy h2 {
    font-size: 50px;
    margin-bottom: 20px;
  }

  .servicemenu-container {
    height: 950px;
    margin-bottom: 300px;
  }

  .service-menu-box {
    flex-direction: column;
    margin-bottom: 0px;
  }
  .service-item {
    width: 55%;
    margin-left: 10%;
    margin-bottom: 30px;
  }
  @media screen and (max-width: 600px) {
    .service-item {
      width: 65%;
      height: 80px;
      font-size: 15px;
      line-height: 80px;
    }
  }
}
</style>
