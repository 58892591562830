<template>
  <div>
    <div class="top-newstitle">
      <h1>初開催『IT・情シスDXPO大阪’25【春】』に出展します</h1>
    </div>
    <div class="news-maintext">
      <p>平素は格別のお引き立てを賜り、誠にありがとうございます。</p>
      <p>
        この度、弊社は初開催『IT・情シスDXPO大阪’25【春】』に出展します。<br />
        DXおよび業務のデジタル化実現のために必要不可欠な各種セキュリティやネットワーク環境・ITインフラが集う専門展となっております。
      </p>
      <div>
        <h6>展示会情報</h6>
        <ul>
          <li>展示会名称:IT・情シスDXPO大阪’25【春】</li>
          <li>会期:2025年3月13日（木）・2025年3月14日（金）</li>
          <li>開催時間:9:30～17:00</li>
          <li>
            会場：インテックス大阪 4・5号館（大阪府大阪市住之江区南港北1-5-102）
          </li>
          <li>弊社ブース番号:4号館 小間番号15-10</li>
        </ul>
        <p>皆様のご来場を心よりお待ちしております。</p>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "news2Page",
  components: {},
};
</script>

<style scoped>
h1 {
  height: 350px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 3vw;
  text-align: center;
}

.news-maintext {
  width: 60%;
  margin: auto;
  margin-bottom: 200px;
}
</style>
