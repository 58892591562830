<template>
  <div>
    <img class="comingsoon-img" src="@/static/comingsoon.jpg" alt="ビル" />
    <div>
      <p>coming soon...</p>
    </div>

    <ContactSection />
  </div>
</template>

<script>
export default {
  name: "comingsoonPage",
  components: {},
};
</script>

<style scoped>
.comingsoon-img {
  width: 100%;
  height: 100vh;
  padding-top: 80px;
  aspect-ratio: 16 / 7;
  background-size: cover;
  object-fit: cover;
  display: block;
}

p {
  font-weight: bold;
  font-size: 20px;
  margin: 40px 0 200px 40px;
}
</style>
