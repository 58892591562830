<template>
  <div>
    <div class="top-interviewee-heading">
      <img src="@/static/peopleC.png" alt="女性のシルエット" />
      <div class="interviewee-beginning">
        <h6>コンサルタント事業部 セールス</h6>
        <h6>N.S/2019年中途入社</h6>
        <h3>
          型にはまらない営業スタイルで、<br class="sp" />
          情報セキュリティ分野をリードする
        </h3>
      </div>
    </div>
    <div class="interview-container">
      <div class="interview-box">
        <h4><span>Q1. </span>入社を決めた理由を教えてください。</h4>
        <h5>お客様に寄り添った提案ができる</h5>
        <p>
          入社を決めた理由は、情報セキュリティというニッチな分野で多様な提案を行えるチャンスがあったからです。<br />
          この分野は非常に専門性が高い分野で、他の営業職では味わえないような挑戦を経験できる点が魅力でした。<br />
          また、UPFは自分の裁量を大切にしており、型にはまらない営業スタイルで、<br />
          お客様に寄り添った提案ができるという点がとても魅力的でした。
        </p>
      </div>
      <div class="interview-box">
        <h4><span>Q2.</span> 現在どのようなお仕事をされていますか？</h4>
        <h5>第三者認証取得コンサルティングサービスを提案</h5>
        <p>
          現在、私は新規営業を担当しています。<br />
          営業スタイルは完全反響型で、HPからの問い合わせや紹介によるアプローチを通じて、<br />
          お客様に情報セキュリティに関する第三者認証取得コンサルティングサービス（Pマーク、ISMS、TISAX、ISOなど）を提案しています。<br />
          加えて、情報セキュリティ監査やリスクマネジメントに関する営業も行っており、非常に幅広い分野で活動しています。<br />
          お客様のニーズに合わせて、最適な提案ができることを大切にしています。
        </p>
      </div>
      <div class="interview-box">
        <h4><span>Q3.</span> やりがいを感じるのはどんなときですか？</h4>
        <h5>
          お客様から信頼を得て長期的な関係を築けた時に大きなやりがいを感じる
        </h5>
        <p>
          やりがいを感じる瞬間は、お客様にとって最適な提案をして、感謝の言葉をいただいた時です。<br />
          自分が考えた提案がクライアントの課題解決お客様に寄り添った提案ができると実感できる瞬間は、営業として非常に充実感があります。<br />
          また、情報セキュリティのような専門的な分野で、お客様から信頼を得て長期的な関係を築けた時に、大きなやりがいを感じます。
        </p>
      </div>
      <div class="interview-box">
        <h4><span>Q4. </span>UPFの魅力について教えてください。</h4>
        <h5>社員一人一人に対する信頼と柔軟な働き方を提供してくれる</h5>
        <p>
          UPFの魅力は、何と言っても裁量をもって働ける点です。<br />
          営業スタイルにおいても、型にはまらず自由にアプローチできるので、自分のペースで成果を上げることができます。<br />
          また、社員一人一人に対する信頼と柔軟な働き方を提供してくれる点が非常に魅力的です。
        </p>
      </div>
      <div class="interview-box">
        <h4>
          <span>Q5.</span> 応募を考えている方へのメッセージをお願いします！
        </h4>
        <h5>自分の成長を実感できる環境が待っています</h5>
        <p>
          UPFの営業職は、大きな自由度を持ち、裁量を発揮できる環境があります。<br />
          型にはまらない営業スタイルでお客様に寄り添い、カスタマイズした提案ができるので、大きなやりがいを感じることが出来ます。<br />
          情報セキュリティというニッチな分野での挑戦もできるので、専門的な知識を深めながら営業として成長することができます。<br />
          もし少しでも興味があれば、ぜひ一度試してみてください。<br />
          きっと、自分の成長を実感できる環境が待っています。
        </p>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "IntervieweeNSPage",
  components: {},
};
</script>

<style scoped>
.top-interviewee-heading {
  padding-top: 200px;
  padding-bottom: 100px;
  display: flex;
  background-color: #f4faff;
  box-shadow: 0px -2px 10px 0px rgb(129, 171, 172);
}

.top-interviewee-heading img {
  width: 600px;
  height: 600px;
  margin-left: 10%;
  margin-right: 5%;
  background-color: #fff;
  object-fit: cover;
  object-position: top;
  /* border: 1px solid #000; */
  border-radius: 30px;
  box-shadow: 0 0 8px rgb(129, 155, 172);
}

.interviewee-beginning {
  margin-top: 30px;

  & h6 {
    font-size: 20px;
  }

  & h3 {
    margin-top: 30px;
    font-size: 40px;
    font-weight: bold;
    color: #00417f;
  }
}

.interviewee-beginning h3 span {
  display: inline-block;
}

.interview-container {
  width: 70%;
  margin: auto;
  margin-bottom: 250px;
  margin-top: 150px;
}

.interview-box {
  margin-bottom: 80px;
  /* border: 1px solid #000; */
}

span {
  font-size: 40px;
  color: #00417f;
}

.interview-box h4 {
  padding-bottom: 40px;
  padding-left: 30px;
  font-size: 30px;
  font-weight: bold;
  border-bottom: 1px solid #a6c0d8;
}

.interview-box h5 {
  padding-left: 30px;
  margin-top: 30px;
  margin-bottom: 30px;
  font-size: 25px;
  font-weight: bold;
  color: #155591;
}

.interview-box p {
  padding-left: 30px;
  font-size: 18px;
}

@media screen and (max-width: 1400px) {
  .sp {
    display: none;
  }
}

@media screen and (max-width: 1000px) {
  .top-interviewee-heading {
    display: flex;
    flex-direction: column;
  }

  .top-interviewee-heading img {
    width: 80%;
    height: 500px;
  }

  .interviewee-beginning h6,
  .interviewee-beginning h3 {
    margin: auto;
    margin-top: 10px;
    width: 80%;
  }

  .interviewee-beginning h3 {
    font-size: 30px;
  }

  .interview-container {
    width: 90%;
  }
}

@media screen and (max-width: 700px) {
  .top-interviewee-heading img {
    height: 400px;
  }

  span {
    font-size: 30px;
  }

  .interview-box h4 {
    font-size: 25px;
  }

  .interview-box h5 {
    font-size: 20px;
  }

  .interview-box p {
    font-size: 15px;
  }
}
</style>
