<template>
  <div>
    <TopSection />
    <CompanySection />
    <ConsultingSection />
    <ServiceSection />
    <video
      class="secondmov"
      src="@/static/mov--2.mp4"
      autoplay
      loop
      muted
      playsinline
    ></video>
    <RecruitSection />
    <NewsSection />
    <ContactSection id="contact-section" />
  </div>
</template>

<script>
import TopSection from "@/components/organisms/TopSection.vue";
import CompanySection from "@/components/organisms/CompanySection.vue";
import ConsultingSection from "@/components/organisms/ConsultingSection.vue";
import ServiceSection from "@/components/organisms/ServiceSection.vue";
import ContactSection from "@/components/organisms/ContactSection.vue";
import RecruitSection from "@/components/organisms/RecruitSection.vue";
import NewsSection from "@/components/organisms/NewsSection.vue";

export default {
  name: "HomePage",
  components: {
    TopSection,
    CompanySection,
    ServiceSection,
    ConsultingSection,
    ContactSection,
    RecruitSection,
    NewsSection,
  },
};
</script>

<style scoped>
button:hover {
  transition: background-image 1s ease-in-out;
}

.secondmov {
  width: 100%;
  height: 28vh;
  aspect-ratio: 16 / 7;
  background-size: cover;
  object-fit: cover;
  display: block;
  z-index: -5;
}

@media screen and (max-width: 1000px) {
  h1,
  h2 {
    font-size: 50px;
  }
}
</style>
