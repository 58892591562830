<template>
  <div>
    <div class="top-newstitle">
      <h1>コーポレートサイトを開設しました</h1>
    </div>
    <div class="news-maintext">
      <p>平素は格別のお引き立てを賜り、誠にありがとうございます。</p>
      <p>
        この度、株式会社UPFはコーポレートサイトを開設いたしました。<br />
        企業情報や弊社サービス、採用情報など幅広く情報発信していくとともに、お客様がより使いやすいコーポレートサイトを目指してまいります。<br />
        今後とも、より一層のご活用をくださいますよう、よろしくお願い申し上げます。
      </p>
    </div>
  </div>
</template>

<script>
export default {
  name: "news1Page",
  components: {},
};
</script>

<style scoped>
h1 {
  height: 350px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 3vw;
  text-align: center;
}

.news-maintext {
  width: 60%;
  margin: auto;
  margin-bottom: 200px;
}
</style>
