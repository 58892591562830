<template>
  <div>
    <div class="top-brand-heading">
      <h1>Brand Message</h1>
    </div>
    <h2 class="brandmessage-top">PURPOSE・MISSION・VISION・VALUE</h2>
    <div class="brandmessage-detail">
      <div class="brandmessage-item">
        <h3>
          “パーパス
          <span>(Purpose)</span>
          ”
        </h3>
        <p>安心と成長の未来を創造</p>
      </div>
      <div class="brandmessage-item">
        <h3>
          “ミッション
          <span>(Mission)</span>
          ”
        </h3>
        <p>リスクをコントロールし挑戦を支える</p>
      </div>
      <div class="brandmessage-item">
        <h3>
          “ビジョン
          <span>(Vision)</span>
          ”
        </h3>
        <p>顧客を支えるファーストコールカンパニーになる</p>
      </div>
      <div class="brandmessage-item">
        <h3>UPF Values UPFの一員としての価値観</h3>
        <p>
          customer-focusedbr <br />
          顧客の立場に立ち、ニーズを理解し、最適なサポートを実施します。
        </p>
        <p>
          Results-oriented <br />
          言葉よりも行動と結果で価値を示します。
        </p>
        <p>
          Innovation and creativity<br />
          現状に満足せず、変化を恐れず、挑戦を続けます。
        </p>
        <p>Be honest <br />新たな学びを素直に受け入れ、柔軟に行動します。</p>
        <p>
          Advanced action<br />
          スピードの価値を理解し、何事もスピーディに実行します。
        </p>
        <p>
          Responsibility<br />
          言い訳をせず、全ての結果に対して責任を持ち自己実現に向き合います。
        </p>
        <p>
          Be entertained <br />様々な事象に好奇心を持ちどんな仕事も楽しみます。
        </p>
        <p>
          one team<br />
          仲間に対する信頼と敬意を持ち、お互いの成長を支え合います。
        </p>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "BrandMessagePage",
  components: {},
};
</script>

<style scoped>
.top-brand-heading {
  height: 450px;
  display: flex;
  align-items: center;
  padding-left: 6%;
  padding-top: 8%;
  font-family: "Cormorant Garamond", serif;
  border-bottom: 1px solid #e2e1e1;

  & h1 {
    font-size: clamp(20px, 50px, 70px);
  }
}

h2 {
  font-size: clamp(20px, 2.5vw, 50px);
  text-align: center;
  font-weight: bold;
  margin-top: 150px;
  margin-bottom: 100px;
}

.brandmessage-detail {
  width: 75%;
  margin: auto;
  margin-bottom: 300px;
  padding: 5%;
  border-radius: 30px;
  /* box-shadow: 0px 5px 15px 0px rgba(0, 0, 0, 0.15); */
  box-shadow: inset 0px 0px 10px 0px rgba(0, 0, 0, 0.3);
}

.brandmessage-item {
  margin-bottom: 50px;
}

h3 {
  color: #00417f;
  font-weight: bold;
  font-size: clamp(17px, 2.5vw, 30px);
  padding-bottom: 10px;
  margin-bottom: 10px;
  border-bottom: 1px solid #e2e1e1;
}

span {
  font-size: clamp(12px, 2.5vw, 20px);
}

p {
  font-size: clamp(14px, 2.5vw, 20px);
}
</style>
