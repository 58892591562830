<template>
  <div class="card-box">
    <img class="card-img" :src="imageUrl" alt="" />
    <h6>{{ title }}</h6>
    <div class="description-container">
      <p class="card-description">{{ description }}</p>
      <div class="card-slot">
        <slot></slot>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "CardWithThumb",
  props: {
    imageUrl: {
      type: String,
      required: true,
    },
    title: {
      type: String,
      required: true,
    },
    description: {
      type: String,
      required: true,
    },
  },
};
</script>

<style scoped>
.card-box {
  margin: 10vh 10vw;
  width: 350px;
  height: 550px;
  object-fit: cover;
  background-color: #fff;
  border-radius: 3%;
  overflow: hidden;
  box-shadow: 0px 2px 11px -4px rgba(0, 0, 0, 0.35);
}

.card-box > h6 {
  font-weight: bold;
  font-size: 22px;
  margin: 20px 25px;
}

.card-img {
  height: 250px;
  min-height: 200px;
  width: 100%;
  object-fit: cover;
}

.card-description {
  min-height: 150px;
  font-size: 15px;
  margin: 10px 25px;
}

.card-box > a {
  border-radius: 30px;
  background-color: #00417f;
  color: #fff;
  padding: 10px 20px;
  margin-left: 22px;
  font-size: 16px;
  transition: color 0.2s, background-color 0.2s;
}

.card-box > a:hover {
  background-color: #fff;
  border: 1px solid #00417f;
}
</style>
