<template>
  <div>
    <div class="top-contact-heading">
      <h1>送信内容の確認</h1>
    </div>
    <div v-if="formData">
      <div class="contactform-containr">
        <p class="contactform-text">
          送信内容を確認の上、「送信」ボタンを押してください。
        </p>
        <form @submit.prevent="handleSubmit">
          <FormItem class="mb-3" name="お問い合わせ内容の種類">
            {{ inquiryTypes }}
          </FormItem>

          <!-- 会社名 -->
          <FormItem class="mb-3" name="会社名">
            {{ formData.companyName }}
          </FormItem>

          <!-- 部署名 -->
          <FormItem class="mb-3" name="部署名">
            {{ formData.affiliation }}
          </FormItem>

          <!-- お名前 -->
          <FormItem class="mb-3" name="お名前">
            {{ formData.name }}
          </FormItem>

          <!-- メールアドレス -->
          <FormItem class="mb-3" name="メールアドレス">
            {{ formData.email }}
          </FormItem>

          <!-- 電話番号 -->
          <FormItem class="mb-3" name="電話番号">
            {{ formData.phoneNumber }}
          </FormItem>

          <!-- お問い合わせ内容 -->
          <FormItem class="mb-3" name="お問い合わせ内容">
            {{ formData.message }}
          </FormItem>

          <!-- 個人情報の取り扱い -->
          <FormItem class="mb-3" name="個人情報の取り扱い">
            <div class="checkbox-container">
              <div class="checkbox-item">
                <input
                  type="checkbox"
                  name="consent"
                  v-model="formData.consent"
                />
                <label>
                  <a href="#/privacypolicy" target="_blank"
                    >「個人情報の取り扱いについて」</a
                  >に同意する
                </label>
              </div>
            </div>
          </FormItem>
        </form>
      </div>
      <div class="sent-message-container deactive">
        <p class="sent-message">
          送信しました。<br />
          お問い合わせありがとうございます。<br />
          3秒後にトップページへ自動的に移動します。
        </p>
      </div>
      <div class="error-message-container deactive">
        <p class="error-message">
          送信に失敗しました。<br />
          もう一度お試しください。
        </p>
      </div>
      <div class="actions">
        <button class="browser-back" @click="$router.go(-1)">戻る</button>
        <button class="send" @click="submitForm">送信する</button>
      </div>
    </div>
    <div v-else>
      <p>フォームデータが見つかりません。</p>
      <button @click="$router.push('/')">入力画面に戻る</button>
    </div>
  </div>
</template>

<script>
import FormItem from "../atoms/FormItem.vue";
import axios from "axios";

export default {
  components: {
    FormItem,
  },
  computed: {
    formData() {
      const data = { ...this.$route.query };

      // クエリパラメータをデコードして取得
      for (const key in data) {
        data[key] = decodeURIComponent(data[key]);
      }

      return data;
    },
    inquiryTypes() {
      switch (this.formData.inquiryType) {
        case "trade":
          return "お取引について";
        case "recruitment":
          return "採用について";
        case "others":
          return "その他";
        default:
          return "";
      }
    },
  },

  methods: {
    async submitForm() {
      try {
        const response = await axios.post(
          "https://upfgroup.co.jp/backend/index.cgi/contact",
          {
            inquiryType: this.inquiryTypes,
            companyName: this.formData.companyName,
            affiliation: this.formData.affiliation,
            name: this.formData.name,
            email: this.formData.email,
            phoneNumber: this.formData.phoneNumber,
            message: this.formData.message,
          }
        );

        const sleep = (time) => new Promise((r) => setTimeout(r, time));

        if (response.status === 200) {
          $(".actions").addClass("deactive");
          $(".sent-message-container").removeClass("deactive");
          $(".sent-message-container").addClass("active");

          $(".error-message-container").removeClass("active");
          $(".error-message-container").addClass("deactive");

          await sleep(3000);
          this.$router.push("/");
        } else {
          $(".error-message-container").addClass("active");
        }
      } catch (error) {
        $(".error-message-container").addClass("active");
      }
    },
  },
};
</script>

<style scoped>
.deactive {
  display: none !important;
}

.active {
  display: block;
}

.sent-message {
  text-align: center;
  margin-bottom: 90px;
}

.error-message {
  color: red;
  text-align: center;
}

.top-contact-heading {
  height: 300px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-bottom: 1px solid #e2e1e1;

  & h1 {
    font-size: clamp(20px, 50px, 70px);
  }
}

.contactform-containr {
  margin: 10% auto;
  min-width: 9rem;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.contactform-text {
  width: 70%;
  font-size: 13px;
  padding-bottom: 40px;
  border-bottom: 1px solid #e2e1e1;
}

form {
  width: 70%;
  margin-top: 50px;
}

a {
  color: #000;
}

.actions {
  display: flex;
  justify-content: center;
  margin-bottom: 200px;
}

.browser-back,
.send {
  display: block;
  width: 30%;
  height: 80px;
  margin: auto;
  font-size: 14px;
  color: #fff;
  background-color: #00417f;
  margin-top: 40px;
}

.browser-back:hover,
.send:hover {
  background-color: #00274d;
}

::v-deep(.form-item-name) {
  width: 300px;
  /* border: 1px solid #000; */
}
</style>
