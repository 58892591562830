<template>
  <div>
    <!-- 個人情報保護方針 -->
    <div class="top-privacy-policy-heading">
      <h1>個人情報保護方針</h1>
    </div>
    <div class="privacy-policy-container">
      <div class="date-enactment-privacy-policy">
        <p>制定日 2012年6月1日</p>
        <p>最終改正日 2021年7月1日</p>
      </div>
      <div class="privacy-policy-box">
        <p>
          株式会社UPF（以下、「当社」という。）は、当社にとって個人情報及び特定個人情報の保護が重大な責務であると認識しております。
          そこで個人情報保護理念と自ら定めた行動規範に基づき、以下に示す方針を具現化するための個人情報保護マネジメントシステムを構築し、
          最新のＩＴ技術の動向、社会的要請の変化、経営環境の変動等を常に認識しながら、その継続的改善に、全社を挙げて取り組むことをここに宣言致します。
        </p>
        <p>
          1.
          当社は、事業の内容及び規模を考慮した適切な個人情報の取得・利用及び提供を行い、特定された利用目的の達成に必要な範囲を超えた個人情報の取扱いを行ないません。また、そのための措置を講じます。
        </p>
        <p>
          2.
          当社は個人情報の取扱いに関する法令、国が定める指針その他の規範を遵守致します。
        </p>
        <p>
          3.
          当社は個人情報の漏えい、滅失、き損などのリスクに対して、合理的な安全対策を講じて防止する規程、体制を構築し、継続的に向上させていきます。また、万一の際には速やかに是正措置を講じます。
        </p>
        <p>
          4.
          当社は個人情報取扱いに関する苦情及び相談に対しては、迅速かつ誠実に対応致します。
        </p>
        <p>
          5.
          個人情報保護マネジメントシステムは、当社を取り巻く環境の変化と実情を踏まえ、適時・適切に見直して継続的に改善をはかっていきます。
        </p>
      </div>
      <div class="president">
        <p>株式会社UPF 代表取締役 仲手川 啓</p>
        <p>以上</p>
      </div>
      <div class="contact-info">
        <p>
          【個人情報保護方針に関するお問い合わせ先 兼
          個人情報に関する苦情・相談窓口】
        </p>
        <p>〒103-0001 東京都中央区日本橋小伝馬町2-4 三報ビルディング5階</p>
        <p>株式会社UPF 個人情報に関するお問い合わせ窓口</p>
        <p>メールアドレス：kanri@upfgroup.co.jp</p>
        <p>TEL：03-6661-0846（受付時間：平日9：00～18：00）</p>
      </div>
    </div>

    <!-- 個人情報の取扱いについて-->
    <div class="top-privacy-policy-heading">
      <h1>個人情報の取扱いについて</h1>
    </div>
    <div class="privacy-policy-container">
      <div class="privacy-policy-box">
        <h2>個人情報の利用目的</h2>
        <p>当社は取得する個人情報を以下に示す目的で利用いたします。</p>
        <ul>
          <li>
            <strong>(1) お客様の個人情報</strong>
            <ul>
              <li>
                お客様の個人情報は、当社のコンサルティング業務におけるサービスの提供、お客様へのご連絡、関連するアフターサービス、新商品や新たなサービスのご案内などに利用します。
              </li>
            </ul>
          </li>
          <li>
            <strong>(2) お問い合わせ、資料請求をいただいた方の個人情報</strong>
            <ul>
              <li>
                当社の各事業に関するお問い合わせの方の個人情報は、お問い合わせにお答えするために利用します。
              </li>
              <li>ご要望いただいた資料の送付などに利用します。</li>
            </ul>
          </li>
          <li>
            <strong>(3) 当社の株主様の個人情報</strong>
            <ul>
              <li>
                当社の株主さまへの個人情報は、連絡、各種情報等の提供および株主管理及び関連・付帯する業務に利用します。
              </li>
            </ul>
          </li>
          <li>
            <strong>(4) 当社の従業者情報</strong>
            <ul>
              <li>
                人事労務管理、業務管理、福利厚生、健康管理、セキュリティ管理などに利用します。
              </li>
            </ul>
          </li>
          <li>
            <strong>(5) 採用応募者の個人情報</strong>
            <ul>
              <li>採用選考及びそれに伴う連絡などに利用します。</li>
            </ul>
          </li>
          <li>
            <strong>(6) 個人事業主の個人情報</strong>
            <ul>
              <li>業務の連絡や、業務委託費支払いの手続きなどに利用します。</li>
            </ul>
          </li>
          <li>
            <strong>(7) ご提供いただいた個人番号情報</strong>
            <ul>
              <li>
                法律で特定された「社会保険手続き」、「税務処理」などに利用します。
              </li>
            </ul>
          </li>
        </ul>
        <p>以上</p>
      </div>
    </div>

    <!-- 保有個人データ及び第三者提供記録に関する事項の周知について-->
    <div class="top-privacy-policy-heading">
      <h1>保有個人データ及び第三者提供記録に関する事項の周知について</h1>
    </div>
    <div class="privacy-policy-container">
      <div class="privacy-policy-box">
        <p>
          株式会社UPF（以下、「当社」という。）では、保有個人データの開示等（利用目的の通知、開示、内容の訂正、追加又は削除、
          利用の停止、消去、第三者への提供の停止及び第三者提供記録）に対する請求について、以下の事項を周知致します。
        </p>

        <h2>1. 当社の名称及び住所、代表者の氏名</h2>
        <ul>
          <li>名称：株式会社UPF</li>
          <li>
            住所：〒103-0001 東京都中央区日本橋小伝馬町2-4 三報ビルディング5階
          </li>
          <li>代表者：代表取締役 仲手川 啓</li>
        </ul>

        <h2>2. 個人情報保護管理者</h2>
        <p>
          コンサルティング事業部 コンサルタントリーダー<br />
          メールアドレス：kanri@upfgroup.co.jp<br />
          TEL：03-6661-0846（受付時間：平日9：00～18：00）
        </p>

        <h2>3. 保有個人データの利用目的</h2>
        <ul>
          <li>
            <strong>(1) 当社が直接取得したお客様の個人情報</strong>
            <ul>
              <li>
                お客様の個人情報は、当社のコンサルティング業務におけるサービスの提供、
                お客様へのご連絡、関連するアフターサービス、新商品や新たなサービスのご案内などに利用します。
              </li>
            </ul>
          </li>
          <li>
            <strong>(2) お問い合わせ、資料請求をいただいた方の個人情報</strong>
            <ul>
              <li>
                当社の各事業に関するお問い合わせの方の個人情報は、お問い合わせにお答えするならびにために利用します。
              </li>
              <li>ご要望いただいた資料の送付などに利用します。</li>
            </ul>
          </li>
          <li>
            <strong>(3) 当社の株主様の個人情報</strong>
            <ul>
              <li>
                当社の株主さまへの個人情報は、連絡、各種情報等の提供および株主管理及び関連・付帯する業務に利用します。
              </li>
            </ul>
          </li>
          <li>
            <strong>(4) 当社の従業者情報</strong>
            <ul>
              <li>
                人事労務管理、業務管理、福利厚生、健康管理、セキュリティ管理などに利用します。
              </li>
            </ul>
          </li>
          <li>
            <strong>(5) 採用応募者の個人情報</strong>
            <ul>
              <li>採用選考及びそれに伴う連絡などに利用します。</li>
            </ul>
          </li>
          <li>
            <strong>(6) 採用応募者の個人情報</strong>
            <ul>
              <li>採用選考及びそれに伴う連絡などに利用します。</li>
            </ul>
          </li>
          <li>
            <strong>(7) 個人事業主の個人情報</strong>
            <ul>
              <li>業務の連絡や、業務委託費支払いの手続きなどに利用します。</li>
            </ul>
          </li>
          <li>
            <strong>(8) ご提供いただいた個人番号情報</strong>
            <ul>
              <li>
                法律で特定された「社会保険手続き」、「税務処理」などに利用します。
              </li>
            </ul>
          </li>
        </ul>

        <h2>4. 保有個人データ取扱いに関する苦情の申し出先</h2>
        <p>
          保有個人データに関するお問合せにつきましては、下記窓口で受付けております。
        </p>
        <p>
          〒103-0001 東京都中央区日本橋小伝馬町2-4 三報ビルディング5階<br />
          株式会社UPF 個人情報に関するお問い合わせ窓口<br />
          メールアドレス：kanri@upfgroup.co.jp<br />
          TEL：03-6661-0846（受付時間：平日9：00～18：00）
        </p>

        <h2>5. 当社の加入する認定個人情報保護団体について</h2>
        <p>当社の所属する認定個人情報保護団体の名称と苦情の解決の申し出先</p>
        <p>
          【認定個人情報保護団体の名称】<br />
          一般財団法人日本情報経済社会推進協会<br />
          【苦情の解決の申出先】<br />
          認定個人情報保護団体事務局<br />
          〒106-0032 東京都港区六本木一丁目9番9号 六本木ファーストビル内<br />
          電話番号：03-5860-7565 ／ 0120-700-779<br />
          （当社の商品・サービスに関する問合せ先ではございません）
        </p>

        <h2>6. 開示等の手続きについて</h2>
        <p>
          開示等のご請求がございます場合には、上記個人情報に関するお問い合わせ窓口までご連絡をお願いします。
          請求に必要な手順の説明と必要な申請書類などをお送りします。
        </p>

        <h2>7. 保有個人データ等の安全管理のために講じた措置</h2>
        <p>
          当社では、個人情報、特定個人情報の取扱いに関する規程、及び安全対策に関する規程を定め以下の措置を講じております。
        </p>
        <ul>
          <li>
            <strong>(1) 基本方針の策定</strong>
            <ul>
              <li>
                個人情報の取扱いに関して方針を定め、個人情報保護方針として、社内に周知徹底するとともに、一般の方も入手できるようにウェブページで公開しています。
              </li>
            </ul>
          </li>
          <li>
            <strong>(2) 個人データの取扱いに係る規律の整備</strong>
            <ul>
              <li>
                取得、利用、保存、提供、削除・廃棄等の段階ごとに、取扱方法、責任者・担当者及びその任務等について個人情報の取扱い手順を定め、規程文書としてまとめ、社内に周知しております。
              </li>
            </ul>
          </li>
          <li>
            <strong>(3) 組織的安全管理措置</strong>
            <ul>
              <li>
                個人情報の取扱状況について、定期的に自己点検を実施するとともに、他部署や外部の者による公平な立場からの内部監査を定期的に実施しています。
              </li>
              <li>各個人情報を取扱う従業者を制限しています。</li>
            </ul>
          </li>
          <li>
            <strong>(4) 人的安全管理措置</strong>
            <ul>
              <li>
                個人情報の取扱いに関する留意事項について、従業者に定期的な研修を実施しております。
              </li>
              <li>従業者から、秘密保持に関する誓約を得ています。</li>
            </ul>
          </li>
          <li>
            <strong>(5) 物理的安全管理措置</strong>
            <ul>
              <li>
                取扱い担当者以外の従業者や他の権限を有しない者による個人情報の間覧を防止するため、取り扱う区域を限定しています。
              </li>
              <li>
                個人情報を取扱う区域において、従業者の入退出管理を行っております。
              </li>
              <li>
                個人情報は、施錠できるキャビネットやアクセス制限を行っているサーバに保管しています。
              </li>
            </ul>
          </li>
          <li>
            <strong>(6) 技術的安全管理措置</strong>
            <ul>
              <li>
                サーバなどへの外部からの不正アクセスを防ぐために、ファイアウォールなどを導入しています。また、コンピューターウイルスなどの不正ソフトウェアへの対策を行っています。
              </li>
              <li>個人情報の移送時は、以下の対策をとっております。</li>
              <ul>
                <li>
                  媒体の移送時には、配送記録が残る方法を利用するか、直接手渡しするようにしています。
                </li>
                <li>
                  電子データの通信には、暗号化するなどの漏洩対策を行っています。
                </li>
              </ul>
            </ul>
          </li>
          <li>
            <strong>(7) 外的環境の把握</strong>
            <ul>
              <li>
                当社が外国において個人データを取り扱う場合には、その外的環境を分析・把握し、該当国に適用される法令等を把握したうえで、必要な安全管理措置を講じるものとします。
              </li>
            </ul>
          </li>
        </ul>
        <p class="end">以上</p>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "PersonalInformationPolicyPage",
  components: {},
};
</script>

<style scoped>
h1 {
  height: 350px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 40px;
  text-align: center;
}

.privacy-policy-container {
  width: 60%;
  margin: auto;
  margin-bottom: 200px;
}

.privacy-policy-box {
  margin: 70px 0;

  & p {
    margin-bottom: 30px;
  }
}

.date-enactment-privacy-policy,
.president,
.end {
  text-align: right;
}

.contact-info {
  width: 60%;
  margin: auto;
  margin-bottom: 10px;
}

ul {
  list-style-type: none;
  padding-left: 0;

  li {
    margin-bottom: 20px;

    & > ul {
      list-style-type: disc;
      padding-left: 20px;
    }
  }
}

.privacy-policy-box {
  margin: 70px 0;

  h2 {
    margin-top: 40px;
    margin-bottom: 20px;
    font-size: 24px;
  }

  p,
  ul {
    margin-bottom: 20px;
  }

  ul {
    list-style-type: none;
    padding-left: 0;

    li {
      margin-bottom: 10px;

      & > ul {
        list-style-type: disc;
        padding-left: 20px;
      }
    }
  }
}
</style>
