<template>
  <a :href="url" target="_blank" class="button-rounded">{{ text }}</a>
</template>

<script>
export default {
  name: "RoundedButton",
  props: {
    url: {
      type: String,
      required: true,
    },
    text: {
      type: String,
      required: true,
    },
  },
};
</script>

<style scoped>
.button-rounded {
  border-radius: 30px;
  background-color: #00417f;
  color: #fff !important;
  padding: 10px 20px;
  margin-left: 22px;
  font-size: 14px;
  transition: color 0.2s, background-color 0.2s;
}

.button-rounded:hover {
  font-size: 14px;
  color: #000 !important;
  cursor: pointer;
  background-color: #fff;
  border: 1px solid #00417f;
}
</style>
