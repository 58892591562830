<template>
  <div>
    <div class="top-privacy-policy-heading">
      <h1>個人情報の取扱いについて</h1>
    </div>
    <div class="privacy-policy-container">
      <div class="privacy-policy-box">
        <p class="mb-5">
          この入力フォームで取得する個人情報の取り扱いは下記3項の利用目的のためであり、この目的の範囲を超えて利用することはございません。
        </p>

        <h4>1. 組織の名称</h4>
        <p>組織の名称：株式会社UPF</p>

        <h4>2. 個人情報を関する管理者の氏名、所属及び連絡先</h4>
        <p>
          個人情報保護管理者：コンサルティング事業部 コンサルタントリーダー<br />
          メールアドレス：kanri@upfgroup.co.jp<br />
          TEL：03-6661-0846（受付時間：平日9：00～18：00）
        </p>

        <h4>3. 個人情報の利用目的</h4>
        <ul>
          <li>
            当社の各事業に関するお問い合わせの方の個人情報は、お問い合わせにお答えする及びご要望のあった資料などをお送りするため
          </li>
          <li>
            当社の採用応募の方の個人情報は、採用業務（選考、関連する連絡等）で使用するため
          </li>
        </ul>

        <h4>4. 個人情報の第三者提供</h4>
        <p>
          当社は、ご提供いただいた個人情報を次の場合を除き第三者に提供いたしません。
        </p>
        <ul>
          <li>ご本人の同意がある場合</li>
          <li>法令に基づく場合</li>
          <li>
            人の生命、身体又は財産の保護のために必要がある場合であって、人の同意を得ることが困難であるとき
          </li>
          <li>
            公衆衛生の向上又は児童の健全な育成の推進のために特に必要がある場合であって、本人の同意を得ることが困難であるとき
          </li>
          <li>
            国の機関若しくは地方公共団体又はその委託を受けた者が法令の定める事務を遂行することに対して協力する必要がある場合であって、
            本人の同意を得ることによって当該事務の遂行に支障を及ぼすおそれがあるとき
          </li>
        </ul>

        <h4>5. 個人情報取扱いの委託</h4>
        <p>
          当社は、事業運営上業務の一部を外部に委託しており、業務委託先に対しては、個人情報を預けることがあります。この場合、個人情報を適切に取り扱っていると認められる委託先を選定し、契約等において個人情報の適正管理・機密保持などによりお客様の個人情報の漏洩防止に必要な事項を取決め、適切な管理を実施させます。
        </p>

        <h4>6. 個人情報の開示等の請求</h4>
        <p>
          お客様が当社に対してご自身の個人情報の開示等（利用目的の通知、開示、内容の訂正・追加・削除、利用の停止、消去及び第三者への提供の停止、第三者提供記録の開示）に関して、当社の「個人情報に関するお問合わせ窓口」に申し出ることができます。その際、当社はご本人確認させていただいたうえで、合理的な期間内に対応いたします。詳細につきましては、下記の「個人情報に関するお問い合わせ窓口」までお問い合わせください。
        </p>
        <p>
          〒103-0001 東京都中央区日本橋小伝馬町2-4 三報ビルディング5階<br />
          株式会社UPF 個人情報に関するお問い合わせ窓口<br />
          メールアドレス：kanri@upfgroup.co.jp<br />
          TEL：03-6661-0846（受付時間：平日9：00～18：00）
        </p>
        <h4>7.個人情報を提供されることの任意性について</h4>
        <p>
          お客様が当社に個人情報を提供されるかどうかは、お客様の任意によるものです。ただし、必要な項目をいただけない場合、各サービス等が適切な状態で提供できない場合があります。
        </p>
        <h4>
          8.本Webサイトへアクセスしたことを契機として機械的に取得される情報
        </h4>
        <P
          >当社は、閲覧されたWebサイトのセキュリティ確保・ユーザーサービス向上のため、Cookieにより閲覧された方の情報を取得することがあります。</P
        >
        <p class="end mt-5">以上</p>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "PrivacyPolicyPage",
  components: {},
};
</script>

<style scoped>
h1 {
  height: 350px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 40px;
  text-align: center;
}

.privacy-policy-container {
  width: 60%;
  margin: auto;
  margin-bottom: 200px;
}

.privacy-policy-box {
  margin: 70px 0;

  h4 {
    font-size: 18px;
    margin-bottom: 20px;
  }

  p,
  ul {
    margin-bottom: 20px;
  }

  ul {
    list-style-type: disc;
    padding-left: 20px;
  }
}

.end {
  text-align: right;
}
</style>
