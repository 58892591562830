<template>
  <div>
    <div class="top-environment-heading">
      <h1>Environment</h1>
    </div>
    <img
      class="environment-top-img"
      src="@/static/upfcmpany1.jpeg"
      alt="会社と青空"
    />
    <div class="companyenvironment-container">
      <h2 class="companyenvironment-heading">数字で見るUPF</h2>
      <div class="companyenvironment-overview">
        <div class="companyenvironment-overviewbox">
          <p>Numbers 01</p>
          <h6>従業員数</h6>
          <h2>35<small>名</small></h2>
        </div>
        <div class="companyenvironment-overviewbox">
          <p>Numbers 02</p>
          <h6>平均年齢</h6>
          <h2>28<small>歳</small></h2>
        </div>
        <div class="companyenvironment-overviewbox">
          <p>Numbers 03</p>
          <h6>男女比</h6>
          <h2><small>男性</small>60<small>% 女性</small>40<small>%</small></h2>
        </div>
        <div class="companyenvironment-overviewbox">
          <p>Numbers 04</p>
          <h6>新卒採用継続年数</h6>
          <h2>5<small>年</small></h2>
          <p>2021年より新卒採用を開始しました!</p>
        </div>
        <div class="companyenvironment-overviewbox">
          <p>Numbers 05</p>
          <h6>年間休日数（2023年度実績）</h6>
          <h2>125<small>日</small></h2>
        </div>
        <div class="companyenvironment-overviewbox">
          <p>Numbers 06</p>
          <h6>有給休暇取得率</h6>
          <h2>95<small>%</small></h2>
        </div>
        <div class="companyenvironment-overviewbox">
          <p>Numbers 07</p>
          <h6>産休取得率</h6>
          <h2>100<small>%</small></h2>
          <p>（男性含）</p>
        </div>
        <div class="companyenvironment-overviewbox">
          <p>Numbers 08</p>
          <h6>月平均所定外労働時間（2023年度実績）</h6>
          <h2>8<small>時間</small></h2>
          <p>バックオフィス部門:0分</p>
          <p>
            ※残業は申請制で、原則定時退社を推奨。<br />
            仕事とプライベートの両立が可能です！
          </p>
        </div>
      </div>
    </div>
    <div class="benefits-container">
      <h2>福利厚生</h2>
      <div class="benefits-details">
        <div class="benefits-detailbox">
          <img src="@/static/insurance.png" alt="手とハート" />
          <div>
            <h4>各種保険</h4>
            <p>【各種社会保険完備】</p>
            <ul>
              <li>雇用保険</li>
              <li>厚生年金</li>
              <li>労災保険</li>
              <li>健康保険</li>
            </ul>
          </div>
        </div>
        <div class="benefits-detailbox">
          <img src="@/static/allowance.png" alt="手と家" />
          <div>
            <h4>各種手当</h4>
            <ul>
              <li>交通費支給（上限あり）</li>
              <li>資格取得支援手当</li>
              <li>住宅手当（一部条件あり）</li>
              <li>役職手当</li>
              <li>出張手当</li>
            </ul>
          </div>
        </div>
        <div class="benefits-detailbox">
          <img src="@/static/holiday.png" alt="カレンダー" />
          <div>
            <h4>休日・休暇</h4>
            <ul>
              <li>年間休日120日以上</li>
              <li>完全週休二日制（土日祝休み）</li>
              <li>GW</li>
              <li>夏季休暇</li>
              <li>慶弔休暇</li>
              <li>年末年始休暇</li>
              <li>有給休暇（入社半年後より付与）</li>
            </ul>
          </div>
        </div>
        <div class="benefits-detailbox">
          <img src="@/static/benefits.png" alt="" />
          <div>
            <h4>その他福利厚生</h4>
            <ul>
              <li>定期健康診断</li>
              <li>ノートPC・ディスプレイ貸与</li>
              <li>オフィスカジュアルOK</li>
              <li>ウォーターサーバー完備</li>
              <li>社内プロテイン飲み放題</li>
              <li>社内勉強会:月1回 外部講師等を招いた勉強会を実施</li>
              <li>
                資格手当:業務で必要な資格の取得に必要な教材、<br />
                受験費などを会社が全額または一部免除
              </li>
              <p>※一定の基準あり</p>
            </ul>
          </div>
        </div>
      </div>
    </div>
    <div class="faq-container">
      <h2>FAQ</h2>
      <div class="faqs">
        <div class="faq-detail">
          <div class="question">
            <i class="bi bi-question-circle-fill"></i>
            <p class="question">面接はオンラインでも対応していただけますか？</p>
          </div>
          <div class="answer">
            <i class="bi bi-lightbulb"></i>
            <p>
              一次面接は基本的に対面での実施をお願いしております。<br />
              面接の前にオンラインでカジュアル面談が可能です！お気軽に応募ください♪
            </p>
          </div>
        </div>
        <div class="faq-detail">
          <div class="question">
            <i class="bi bi-question-circle-fill"></i>
            <p class="question">昇給はありますか？</p>
          </div>
          <div class="answer">
            <i class="bi bi-lightbulb"></i>
            <p>
              年に2回（4月・10月）の給与改定があります。<br />
              明確な評価基準に基づく改定となっております。
            </p>
          </div>
        </div>
        <div class="faq-detail">
          <div class="question">
            <i class="bi bi-question-circle-fill"></i>
            <p class="question">オフィスカジュアルでの勤務は可能ですか？</p>
          </div>
          <div class="answer">
            <i class="bi bi-lightbulb"></i>
            <p>
              「他の社員の方が不快でない服装」「清潔感のない服装はNG」などの一定の規定はありますが、基本は自由です♪<br />
              &emsp;TPOに合わせて清潔感のある働きやすい服装での勤務が可能です。
            </p>
          </div>
        </div>
        <div class="faq-detail">
          <div class="question">
            <i class="bi bi-question-circle-fill"></i>
            <p class="question">会社の雰囲気を教えてください。</p>
          </div>
          <div class="answer">
            <i class="bi bi-lightbulb"></i>
            <p>
              業務時間内は皆作業をしているので静かな環境ですが、年齢の近い社員が多く、<br />
              親切で協力的な社員が多いこともあり、相談しやすい環境になっています。
            </p>
          </div>
        </div>
        <div class="faq-detail">
          <div class="question">
            <i class="bi bi-question-circle-fill"></i>
            <p>異動や転勤はありますか？</p>
          </div>
          <div class="answer">
            <i class="bi bi-lightbulb"></i>
            <p>本人の希望がない限りありません。</p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "EnvironmentPage",
  components: {},
};
</script>

<style scoped>
.top-environment-heading {
  height: 450px;
  display: flex;
  align-items: center;
  padding-left: 6%;
  padding-top: 8%;
  font-family: "Cormorant Garamond", serif;
  border-bottom: 1px solid #e2e1e1;

  & h1 {
    font-size: clamp(20px, 50px, 70px);
  }
}

.environment-top-img {
  width: 100%;
  height: 700px;
  aspect-ratio: 16 / 7;
  background-size: cover;
  object-fit: cover;
  display: block;
}

.companyenvironment-container h2,
.benefits-container h2 {
  text-align: center;
  font-family: "Noto Serif JP", serif;
}

.companyenvironment-heading {
  margin-top: 150px;
  margin-bottom: 100px;
}
.companyenvironment-overview {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}

.companyenvironment-overviewbox {
  width: 500px;
  height: 400px;
  padding: 50px 30px;
  text-align: center;
  /* border: 1px solid #000; */
}

.companyenvironment-overviewbox h6 {
  margin-bottom: 30px;
  font-size: 22px;
}

small {
  font-size: 30px;
}

.benefits-container h2 {
  margin-top: 150px;
  margin-bottom: 100px;
}

.benefits-details {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
  column-count: 2;
}

.benefits-detailbox {
  width: 600px;
  height: 550px;
  margin: 30px 20px;
  display: flex;
  align-items: center;
  flex-direction: column;
  border: 5px solid #00417f;
  border-radius: 30px;
}

.benefits-detailbox img {
  width: 120px;
  margin-top: 40px;
  margin-bottom: 30px;
}

.benefits-detailbox h4 {
  margin-bottom: 30px;
  text-align: center;
  font-size: 30px;
  color: #00417f;
  font-weight: bold;
}

.benefits-detailbox ul {
  font-size: 18px;
}

.faq-container h2 {
  margin-top: 150px;
  margin-bottom: 100px;
  text-align: center;
}

.faq-container {
  text-align: center;
  margin-bottom: 200px;
}

.faqs {
  width: 60%;
  display: inline-block;
  text-align: left;
}

.question,
.answer {
  display: flex;
}

.question {
  margin-bottom: 30px;
  line-height: 30px;
  font-size: 25px;
  color: #00417f;
}

.question i,
.answer i {
  margin: 0px 20px 0 30px;
  font-size: 35px;
}

.answer {
  padding-bottom: 50px;
  margin-bottom: 30px;
  font-size: 20px;
  border-bottom: 1px solid #b6d5f1;
}
.faq-detail:last-child .answer p {
  margin-top: 15px;
  border-bottom: none;
}

.answer i {
  color: #00417f;
}

@media screen and (max-width: 1000px) {
  .faqs {
    width: 80%;
  }
}
</style>
