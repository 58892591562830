<template>
  <div>
    <div class="top-people-heading">
      <h1>People</h1>
    </div>
    <img
      class="people-top-img"
      src="@/static/peopleSectionImg.jpg"
      alt="people"
    />
    <div class="interviews-container">
      <h2>Interviews</h2>
      <div class="interviewee">
        <div class="interviewee-box">
          <a href="#/IntervieweeTF">
            <img src="@/static/peopleA.png" alt="男性のシルエット" />
          </a>

          <div class="interviewee-profile">
            <p>マネージャーコンサルタント</p>
            <p>T.F</p>
            <p>2022年度入社/新卒</p>
          </div>
        </div>

        <div class="interviewee-box">
          <a href="#/intervieweeMI">
            <img src="@/static/peopleB.png" alt="女性のシルエット" />
          </a>

          <div class="interviewee-profile">
            <p>コンサルタント</p>
            <p>M.I</p>
            <p>2022年度入社/新卒</p>
          </div>
        </div>
        <div class="interviewee-box">
          <a href="#/IntervieweeNS"
            ><img src="@/static/peopleC.png" alt="女性のシルエット"
          /></a>

          <div class="interviewee-profile">
            <p>セールス</p>
            <p>N.S</p>
            <p>2020年度入社/中途</p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "PeoplePage",
  components: {},
};
</script>

<style scoped>
.top-people-heading {
  height: 450px;
  display: flex;
  align-items: center;
  padding-left: 6%;
  padding-top: 8%;
  font-family: "Cormorant Garamond", serif;
  border-bottom: 1px solid #e2e1e1;

  & h1 {
    font-size: clamp(20px, 50px, 70px);
  }
}

.people-top-img {
  width: 100%;
  height: 100vh;
  aspect-ratio: 16 / 7;
  background-size: cover;
  object-fit: cover;
  display: block;
}
.interviews-container {
  margin-bottom: 200px;
}

.interviews-container h2 {
  margin-top: 150px;
  margin-bottom: 80px;
  margin-left: 10%;
}
.interviewee {
  display: flex;
  justify-content: space-around;
  flex-wrap: wrap;
}

.interviewee-box {
  width: 500px;
}

.interviewee-box img {
  width: 100%;
  height: 700px;
  object-fit: cover;
  object-position: top;
  border: 3px solid #00417f;
}

.interviewee-profile {
  width: 400px;
  margin: auto;
  padding-top: 30px;
  padding-bottom: 30px;
  text-align: center;
  position: relative;
  top: -80px;
  color: #fff;
  background-color: #00417f;
  border: 1px solid #fff;
}
</style>
