<template>
  <div>
    <div class="top-interviewee-heading">
      <img src="@/static/peopleA.png" alt="男性のシルエット" />
      <div class="interviewee-beginning">
        <h6>コンサルタント事業部 マネージャーコンサルタント</h6>
        <h6>T.F/2022年新卒入社</h6>
        <h3>自由な環境で成果を出す、最短距離で成長する働き方を追求</h3>
      </div>
    </div>
    <div class="interview-container">
      <div class="interview-box">
        <h4><span>Q1. </span>入社を決めた理由を教えてください。</h4>
        <h5>成果を出せばしっかり評価される会社</h5>
        <p>
          正直に言うと、内定までのスピード感が決め手でした。<br />
          面接の段階から「ここは無駄がない会社だな」と感じましたし、1次面接から社長が出てきたのも印象的でした。<br />
          意思決定が早く、柔軟な環境なら自分の力を最大限発揮できると思い、入社を決めました。<br />
          また、選考の過程で「成果を出せばしっかり評価される会社だ」と感じたことも大きかったですね。<br />
          実際に、年次に関係なく実力次第でキャリアアップできる環境が整っているので、自分の成長次第でどこまでも上を目指せると思いました。
        </p>
      </div>
      <div class="interview-box">
        <h4><span>Q2.</span> 現在どのようなお仕事をされていますか？</h4>
        <h5>情報セキュリティ関連の第三者認証の取得コンサルティング</h5>
        <p>
          情報セキュリティ関連の第三者認証（Pマーク、ISMS、TISAXなど）の取得コンサルティングを担当しています。<br />
          企業規模を問わず、これまで300件以上の案件に携わってきました。<br />
          現在はマネージャーコンサルタントとして、コンサルティング業務に加えて、チームのマネジメントにも力を入れています。<br />
          具体的には、企業の現状を把握し、認証取得に向けた計画を立案した上で、必要な文書作成や教育、内部監査等の運用プロセスの構築をサポートしています。<br />
          また、効率的に業務を進めるためにツールを活用しながら、チーム全体の生産性を高める取り組みも行っています。<br />
        </p>
      </div>
      <div class="interview-box">
        <h4><span>Q3.</span> やりがいを感じるのはどんなときですか？</h4>
        <h5>
          企業文化の一部として情報セキュリティが根付くことにやりがいを感じる
        </h5>
        <p>
          お客様と構築した取り組みが評価され、認証を取得できたときですね。<br />
          特に、初めてPマークやISMSを取る企業の担当者は、最初は何から手をつければいいか分からない状態のことが多いですが、<br />
          きちんと計画を立て、ひとつひとつのタスクをクリアにしていくことで、円滑に進めることが出来ます。<br />
          その結果、お客様から「想像していたよりもスムーズに取得できました」と言われると、自分の仕事の価値を感じます。<br />
          また、お客様の社内でセキュリティ意識が高まり、認証取得後も自発的に改善活動を続けている姿を見ると、<br />
          コンサルタントとしての貢献を実感します。単なる取得支援ではなく、企業文化の一部として情報セキュリティの取り組み根付くことにやりがいを感じます。
        </p>
      </div>
      <div class="interview-box">
        <h4><span>Q4. </span>UPFの魅力について教えてください。</h4>
        <h5>努力がダイレクトに反映される環境</h5>
        <p>
          「自分で好きなように働ける」「やった分だけ評価される」ことです。<br />
          働き方に制約が少なく、自分のやり方で成果を出せるのがいいですね。<br />
          また、実績を出せばその分評価されるので、年次や役職に関係なく、結果次第でしっかり報酬が得られます。<br />
          自分の市場価値を上げたい人にとっては、すごく良い環境だと思います。<br />
          例えば、効率的に業務を進めればその分自由な時間も増えますし、自分の得意な方法で成果を出せるのでストレスが少ないです。<br />
          実際に、私は3年目で年収1,000万円を達成しましたが、それもこの環境だからこそ実現できたと感じています。<br />
          努力がダイレクトに反映される環境なので、モチベーションを高く保てますね。
        </p>
      </div>
      <div class="interview-box">
        <h4>
          <span>Q5.</span> 応募を考えている方へのメッセージをお願いします！
        </h4>
        <h5>最短距離で成長したい人には、ぴったり</h5>
        <p>
          UPFは、結果を出した分だけしっかり評価される会社です。<br />
          決められたやり方に縛られず、自分のスタイルで成果を出せるので、裁量の大きい環境で働きたい人には最適だと思います。<br />
          自分次第でいくらでも成長できる環境が整っているので、挑戦したい人にはおすすめです。<br />
          また、ツールの活用や効率的な業務推進を重視する文化があるので、「頑張り方」よりも「成果の出し方」にフォーカスできるのも魅力です。<br />
          やるべきことをシンプルにこなして、最短距離で成長したい人には、ぴったりの職場だと思います。
        </p>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "IntervieweeTFPage",
  components: {},
};
</script>

<style scoped>
.top-interviewee-heading {
  padding-top: 200px;
  padding-bottom: 100px;
  display: flex;
  background-color: #f4faff;
  box-shadow: 0px -2px 10px 0px rgb(129, 171, 172);
}

.top-interviewee-heading img {
  width: 600px;
  height: 600px;
  margin-left: 10%;
  margin-right: 5%;
  background-color: #fff;
  object-fit: cover;
  object-position: top;
  /* border: 1px solid #000; */
  border-radius: 30px;
  box-shadow: 0 0 8px rgb(129, 155, 172);
}

.interviewee-beginning {
  margin-top: 30px;

  & h6 {
    font-size: 20px;
  }

  & h3 {
    margin-top: 30px;
    font-size: 40px;
    font-weight: bold;
    color: #00417f;
  }
}

.interview-container {
  width: 70%;
  margin: auto;
  margin-bottom: 250px;
  margin-top: 150px;
}

.interview-box {
  margin-bottom: 80px;
  /* border: 1px solid #000; */
}

span {
  font-size: 40px;
  color: #00417f;
}

.interview-box h4 {
  padding-bottom: 40px;
  padding-left: 30px;
  font-size: 30px;
  font-weight: bold;
  border-bottom: 1px solid #a6c0d8;
}

.interview-box h5 {
  padding-left: 30px;
  margin-top: 30px;
  margin-bottom: 30px;
  font-size: 25px;
  font-weight: bold;
  color: #155591;
}

.interview-box p {
  padding-left: 30px;
  font-size: 18px;
}

@media screen and (max-width: 1400px) {
  .sp {
    display: none;
  }
}

@media screen and (max-width: 1000px) {
  .top-interviewee-heading {
    display: flex;
    flex-direction: column;
  }

  .top-interviewee-heading img {
    width: 80%;
    height: 500px;
  }

  .interviewee-beginning h6,
  .interviewee-beginning h3 {
    margin: auto;
    margin-top: 10px;
    width: 80%;
  }

  .interviewee-beginning h3 {
    font-size: 30px;
  }

  .interview-container {
    width: 90%;
  }
}

@media screen and (max-width: 700px) {
  .top-interviewee-heading img {
    height: 400px;
  }

  span {
    font-size: 30px;
  }

  .interview-box h4 {
    font-size: 25px;
  }

  .interview-box h5 {
    font-size: 20px;
  }

  .interview-box p {
    font-size: 15px;
  }
}
</style>
