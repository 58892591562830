import { createRouter, createWebHashHistory } from "vue-router";

import HomePage from "@/components/pages/HomePage.vue";
import CompanyPage from "@/components/pages/CompanyPage.vue";
import NewsPage from "@/components/pages/NewsPage.vue";
import InformationSecurityServicePage from "@/components/pages/InformationSecurityServicePage.vue";
import RiskManagementServicePage from "@/components/pages/RiskManagementServicePage.vue";
import CertificationAccreditationPage from "@/components/pages/CertificationAccreditationPage.vue";
import ContactPage from "@/components/pages/ContactPage.vue";
import RecruitPage from "@/components/pages/RecruitPage.vue";
import BrandMessagePage from "@/components/pages/BrandMessagePage.vue";
import PersonalInformationPolicyPage from "@/components/pages/PersonalInformationPolicyPage.vue";
import InformationSecurityPolicyPage from "@/components/pages/InformationSecurityPolicyPage.vue";
import AntiSocialPolicyPage from "@/components/pages/AntiSocialPolicyPage.vue";
import ConfirmationPage from "@/components/pages/ConfirmationPage.vue";
import ServiceListPage from "@/components/pages/ServiceListPage.vue";
import PeoplePage from "@/components/pages/PeoplePage.vue";
import IntervieweeTFPage from "@/components/pages/IntervieweeTFPage.vue";
import IntervieweeMIPage from "@/components/pages/IntervieweeMIPage.vue";
import IntervieweeNSPage from "@/components/pages/IntervieweeNSPage.vue";
import EnvironmentPage from "@/components/pages/EnvironmentPage.vue";
import PrivacyPolicyPage from "@/components/pages/PrivacyPolicyPage.vue";
import NewGraduateHiringPage from "@/components/pages/NewGraduateHiringPage.vue";
import MidcareerHiringPage from "@/components/pages/Mid-careerHiringPage.vue";
import news1Page from "@/components/pages/news1page.vue";
import news2Page from "@/components/pages/news2page.vue";
import comingsoonpage from "@/components/pages/comingsoonpage.vue";
import EntryFormConfirmationPage from "@/components/pages/EntryFormConfirmationPage.vue";

const routes = [
  {
    path: "/",
    name: "Home",
    component: HomePage,
  },
  {
    path: "/company",
    name: "Company",
    component: CompanyPage,
  },
  {
    path: "/news",
    name: "News",
    component: NewsPage,
  },
  {
    path: "/informationsecurityservice",
    name: "InformationSecurityService",
    component: InformationSecurityServicePage,
  },
  {
    path: "/riskmanagementservice",
    name: "RiskManagementService",
    component: RiskManagementServicePage,
  },
  {
    path: "/certificationaccreditation",
    name: "CertificationAccreditation",
    component: CertificationAccreditationPage,
  },
  {
    path: "/contact",
    name: "Contact",
    component: ContactPage,
  },
  {
    path: "/confirmation",
    name: "Confirmation",
    component: ConfirmationPage,
  },
  {
    path: "/recruit",
    name: "Recruit",
    component: RecruitPage,
  },
  {
    path: "/brandmessage",
    name: "BrandMessage",
    component: BrandMessagePage,
  },
  {
    path: "/personalinformationpolicy",
    name: "PersonalInformationPolicy",
    component: PersonalInformationPolicyPage,
  },
  {
    path: "/informationsecuritypolicy",
    name: "InformationSecurityPolicy",
    component: InformationSecurityPolicyPage,
  },
  {
    path: "/antisocialpolicy",
    name: "AntiSocialPolicy",
    component: AntiSocialPolicyPage,
  },
  {
    path: "/servicelist",
    name: "ServiceList",
    component: ServiceListPage,
  },
  {
    path: "/people",
    name: "People",
    component: PeoplePage,
  },
  {
    path: "/intervieweeTF",
    name: "IntervieweeTF",
    component: IntervieweeTFPage,
  },
  {
    path: "/intervieweeMI",
    name: "IntervieweeMI",
    component: IntervieweeMIPage,
  },
  {
    path: "/intervieweeNS",
    name: "IntervieweeNS",
    component: IntervieweeNSPage,
  },
  {
    path: "/environment",
    name: "Environment",
    component: EnvironmentPage,
  },
  {
    path: "/privacypolicy",
    name: "PrivacyPolicy",
    component: PrivacyPolicyPage,
  },
  {
    path: "/newGraduateHiring",
    name: "NewGraduateHiring",
    component: NewGraduateHiringPage,
  },
  {
    path: "/midcareerHiring",
    name: "Mid-careerHiring",
    component: MidcareerHiringPage,
  },
  {
    path: "/entryFormConfirmation",
    name: "entryFormConfirmation",
    component: EntryFormConfirmationPage,
  },
  {
    path: "/news1",
    name: "news1",
    component: news1Page,
  },
  {
    path: "/news2",
    name: "news2",
    component: news2Page,
  },

  {
    path: "/comingsoon",
    name: "comingsoon",
    component: comingsoonpage,
  },
];

let sleep = async (time) => {
  return new Promise((resolve) => setTimeout(resolve, time));
};

const router = createRouter({
  history: createWebHashHistory(),
  routes,
  async scrollBehavior(to, from, savedPosition) {
    const hash = to.hash || window.location.hash.split("#").pop();
    if (hash) {
      // hashに対応する要素へスクロール
      if (!to.fullPath.startsWith("/#")) {
        await sleep(4000);
      }
      return {
        el: hash,
        behavior: "smooth",
      };
    }

    if (savedPosition) {
      // 戻る/進む時の位置
      return savedPosition;
    }

    // デフォルトでトップにスクロール
    return { top: 0 };
  },
});

export default router;
