<template>
  <PageTransition>
    <Header />
    <router-view></router-view>
    <Footer />
  </PageTransition>
</template>

<script>
import PageTransition from "@/components/PageTransition.vue";
import Header from "@/components/organisms/Header.vue";
import Footer from "@/components/organisms/Footer.vue";
import Lenis from "lenis";

export default {
  name: "App",
  components: {
    PageTransition,
    Header,
    Footer,
  },
};

new Lenis({
  autoRaf: true,
});
</script>

<style>
@import url("https://cdn.jsdelivr.net/npm/bootstrap-icons@1.11.3/font/bootstrap-icons.min.css");

html.lenis,
html.lenis body {
  height: auto;
}

.lenis.lenis-smooth {
  scroll-behavior: auto !important;
}

.lenis.lenis-smooth [data-lenis-prevent] {
  overscroll-behavior: contain;
}

.lenis.lenis-stopped {
  overflow: hidden;
}

.lenis.lenis-smooth iframe {
  pointer-events: none;
}

* {
  margin: 0;
  padding: 0;
}

body {
  font-family: "Noto Serif JP", serif;
}

h1,
h2 {
  font-family: "Cormorant Garamond", serif;
  color: #00417f;
  font-size: 70px;
}

a {
  text-decoration: none;
}

::-webkit-scrollbar {
  width: 8px;
  height: 12px;
}

::-webkit-scrollbar-thumb {
  background: #888;
  border-radius: 6px;
}

::-webkit-scrollbar-track {
  background: rgba(0, 0, 0, 0);
}
</style>
