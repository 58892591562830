<template>
  <div class="swiper">
    <div class="swiper-wrapper"><slot></slot></div>
    <!-- <div class="swiper-button-next"></div>
        <div class="swiper-button-prev"></div> -->
    <!-- ページネーション -->
    <div class="swiper-pagination"></div>
  </div>
</template>

<script>
export default {
  name: "SimpleSwiper",
  mounted() {
    new Swiper(".swiper", {
      slidesPerView: 3, // 1枚ずつ表示
      spaceBetween: 0, // スライド間のスペース
      loop: true, // 無限ループ
      autoplay: {
        delay: 10000, // 10秒ごとに自動スライド
        disableOnInteraction: false, // ユーザー操作後も自動スライド継続
      },
      pagination: {
        el: ".swiper-pagination", // ページネーションの要素
        clickable: true,
      },
      navigation: {
        nextEl: ".swiper-button-next", // 次へボタン
        prevEl: ".swiper-button-prev", // 前へボタン
      },
    });
  },
};
</script>

<style scoped>
.swiper {
  margin-top: 8%;
  margin-bottom: 8%;
  padding: 0;
  width: 1200px;
  float: right;
  background-color: rgba(57, 113, 165, 0.541);
  box-shadow: inset 0px 0px 10px 2px rgba(19, 61, 104, 0.5);
}

.swiper-slide {
  counter-increment: gallery-cell;
}

@media screen and (max-width: 1000px) {
  .swiper {
    margin-top: 3%;
  }
}
</style>
