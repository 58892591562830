<template>
  <div>
    <div class="contactform-containr">
      <p class="contactform-text">
        下記のフォームに必要項目を入力の上、「送信内容を確認」ボタンを押してください。
      </p>
      <form @submit.prevent="handleSubmit">
        <FormItem name="お名前" required="true" :error="errors.name">
          <input
            class="textbox"
            type="text"
            name="name"
            v-model="formData.name"
          />
        </FormItem>

        <FormItem name="ふりがな" required="true" :error="errors.furiganaName">
          <input
            class="textbox"
            type="text"
            name="furiganaName"
            v-model="formData.furiganaName"
          />
        </FormItem>

        <FormItem name="性別" required="true">
          <div class="checkbox-container">
            <div class="checkbox-item">
              <input
                type="radio"
                id="woman"
                name="genderType"
                value="woman"
                v-model="formData.genderType"
                checked
              />
              <label for="woman">女性</label>
            </div>
            <div class="checkbox-item">
              <input
                type="radio"
                id="man"
                name="genderType"
                value="man"
                v-model="formData.genderType"
              />
              <label for="man">男性</label>
            </div>
          </div>
        </FormItem>

        <FormItem name="生年月日" required="true" :error="errors.birthdate">
          <input
            class="textbox birthdate"
            type="date"
            name="birthdate"
            placeholder="西暦"
            v-model="formData.birthdate"
          />
        </FormItem>

        <FormItem name="電話番号" required="true" :error="errors.phoneNumber">
          <input
            class="textbox"
            type="tel"
            name="phone-number"
            placeholder="ハイフンなし"
            v-model="formData.phoneNumber"
          />
        </FormItem>

        <FormItem name="メールアドレス" required="true" :error="errors.email">
          <input
            class="textbox"
            type="email"
            name="email"
            v-model="formData.email"
          />
        </FormItem>

        <FormItem name="ご住所" required="true" :error="errors.residence">
          <div>
            <div>
              <input
                class="textbox postcode"
                type="text"
                name="postcode"
                placeholder="郵便番号ハイフンなし"
                v-model="formData.postcode"
                @input="fetchAddress"
              />
            </div>

            <input
              class="textbox"
              type="text"
              name="residence"
              v-model="formData.residence"
              ref="residence"
            />
          </div>
        </FormItem>

        <FormItem name="希望職種" required="true" :error="errors.inquiryType">
          <div class="checkbox-container">
            <div class="checkbox-item">
              <input
                type="radio"
                id="consultant"
                name="inquiryType"
                value="consultant"
                v-model="formData.inquiryType"
                checked
              />
              <label for="consultant">コンサルタント</label>
            </div>
            <div class="checkbox-item">
              <input
                type="radio"
                id="sales"
                name="inquiryType"
                value="sales"
                v-model="formData.inquiryType"
              />
              <label for="sales">セールス</label>
            </div>
            <div class="checkbox-item">
              <input
                type="radio"
                id="backOffice"
                name="inquiryType"
                value="backOffice"
                v-model="formData.inquiryType"
              />
              <label for="backOffice">バックオフィス</label>
            </div>
          </div>
        </FormItem>

        <FormItem name="卒業学校名" required="true" :error="errors.schoolName">
          <input
            class="textbox"
            type="text"
            name="school-name"
            v-model="formData.schoolName"
          />
        </FormItem>

        <FormItem name="学部・学科" required="true" :error="errors.faculty">
          <input
            class="textbox"
            type="text"
            name="faculty"
            v-model="formData.faculty"
          />
        </FormItem>

        <FormItem name="保有資格" required="true" :error="errors.qualification">
          <textarea
            class="textbox qualification"
            type="text"
            name="qualification"
            v-model="formData.qualification"
          ></textarea>
        </FormItem>

        <FormItem
          name="ご質問・自己PRなど"
          required="true"
          :error="errors.freeSpace"
        >
          <textarea
            class="textbox"
            name="freeSpace"
            v-model="formData.freeSpace"
          ></textarea>
        </FormItem>

        <FormItem
          class="file-attachment"
          name="履歴書・職務経歴書・紹介状など(png,jpeg,pdf 20MBまで)"
          action="file.html"
          method="post"
          enctype="multipart/form-data"
        >
          <div>
            <input
              type="file"
              name="file"
              accept="image/png, image/jpeg, application/pdf"
              multiple
              @change="handleFileUpload"
            />
          </div>
        </FormItem>

        <FormItem
          name="個人情報の取り扱い"
          required="true"
          :error="errors.consent"
        >
          <div class="checkbox-container">
            <div class="checkbox-item">
              <input
                type="checkbox"
                name="consent"
                v-model="formData.consent"
              />
              <label>
                <a href="#/privacypolicy" target="_blank"
                  >「個人情報の取り扱いについて」</a
                >に同意する
              </label>
            </div>
          </div>
        </FormItem>
        <input class="nextbutton" type="submit" value="送信内容を確認" />
      </form>
    </div>
  </div>
</template>

<script>
import { reactive, watch } from "vue";
import FormItem from "../atoms/FormItem.vue";
import { useRouter } from "vue-router";
import emptyCheck from "../utils/validation";
import { setUploadedFiles } from "../utils/uploadedFile";

export default {
  name: "EntryForm",
  components: {
    FormItem,
  },

  setup() {
    const router = useRouter();

    const formData = reactive({
      inquiryType: "consultant",
      genderType: "woman",
      companyName: "",
      affiliation: "",
      name: "",
      furiganaName: "",
      email: "",
      phoneNumber: "",
      postcode: "",
      residence: "",
      schoolName: "",
      faculty: "",
      qualification: "",
      birthdate: "",
      freeSpace: "",
      consent: false,
      fileName: "", // ← ファイル名保持用
    });

    const errors = reactive({
      inquiryType: "",
      companyName: "",
      affiliation: "",
      name: "",
      furiganaName: "",
      email: "",
      phoneNumber: "",
      postcode: "",
      residence: "",
      schoolName: "",
      faculty: "",
      qualification: "",
      birthdate: "",
      freeSpace: "",
      consent: "",
    });

    const emptyCheckFields = [
      { key: "inquiryType", label: "希望職種" },
      { key: "genderType", label: "性別" },
      { key: "postcode", label: "郵便番号" },
      { key: "residence", label: "ご住所" },
      { key: "schoolName", label: "卒業学校名" },
      { key: "faculty", label: "学部・学科" },
      { key: "qualification", label: "保有資格" },
      { key: "name", label: "お名前" },
      { key: "furiganaName", label: "ふりがな" },
      { key: "birthdate", label: "生年月日" },
      { key: "freeSpace", label: "ご質問・自己PRなど" },
      { key: "consent", label: "個人情報の取り扱いへの同意" },
    ];

    setUploadedFiles(null);
    formData.fileName = "";

    const validateEmail = (email) => /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email);
    const validatePhoneNumber = (phone) => /^\d+$/.test(phone);

    const handleFileUpload = (event) => {
      const files = Array.from(event.target.files);
      if (files.length > 0) {
        setUploadedFiles(files);
        formData.fileName = files.map((f) => f.name).join(", ");
      }
    };

    const fetchAddress = async () => {
      if (formData.postcode.length !== 7) return;
      if (formData.residence.length > 0) return;

      try {
        const res = await fetch(
          `https://zipcloud.ibsnet.co.jp/api/search?zipcode=${formData.postcode}`
        );
        const data = await res.json();

        if (data.results && data.results.length > 0) {
          const result = data.results[0];

          const address1 = result.address1 ?? "";
          const address2 = result.address2 ?? "";
          const address3 = result.address3 ?? "";

          formData.residence = `${address1}${address2}${address3}`;
        } else {
          formData.residence = "";
        }
      } catch (error) {
        formData.residence = "";
        // console.error("住所取得エラー:", error);
      }
    };

    watch(
      () => formData.email,
      (val) => {
        errors.email = !val
          ? "メールアドレスを入力してください"
          : !validateEmail(val)
          ? "正しいメールアドレスを入力してください"
          : "";
      }
    );

    watch(
      () => formData.phoneNumber,
      (newPhoneNumber) => {
        if (!newPhoneNumber) {
          errors.phoneNumber = "電話番号を入力してください";
        } else if (!/^\d{10,11}$/.test(newPhoneNumber)) {
          errors.phoneNumber =
            "電話番号は10桁または11桁の数字で入力してください";
        } else if (!validatePhoneNumber(newPhoneNumber)) {
          errors.phoneNumber = "正しい電話番号を入力してください";
        } else {
          errors.phoneNumber = "";
        }
      }
    );

    const validateForm = () => {
      let isValid = true;

      if (!formData.email || !validateEmail(formData.email)) {
        errors.email = !formData.email
          ? "メールアドレスを入力してください"
          : "正しいメールアドレスを入力してください";
        isValid = false;
      }

      if (!formData.phoneNumber || !validatePhoneNumber(formData.phoneNumber)) {
        errors.phoneNumber = !formData.phoneNumber
          ? "電話番号を入力してください"
          : "正しい電話番号を入力してください";
        isValid = false;
      }

      emptyCheckFields.forEach(({ key, label }) => {
        errors[key] = emptyCheck(formData[key], label);
        if (errors[key]) isValid = false;
      });

      return isValid;
    };

    const handleSubmit = () => {
      if (validateForm()) {
        router.push({
          path: "/entryFormConfirmation",
          query: {
            ...formData,
            fileName: formData.fileName,
            from: router.currentRoute.value.path,
          },
        });
      }
    };

    return {
      formData,
      errors,
      handleSubmit,
      handleFileUpload,
      fetchAddress,
    };
  },
};
</script>

<style scoped>
.error {
  color: #f00;
}

.checkbox-container {
  gap: 10px;
  padding-bottom: 25px;
}

.textbox {
  display: flex;
  width: 39vw;
  max-width: 100%;
  border: none;
  padding: 1rem 0.5rem;
  border-radius: 0.3rem;
  background-color: #f1f3f7;
}

.checkbox-item {
  display: flex;
  gap: 8px; /* チェックボックスとラベル間の間隔 */
}

input[type="checkbox"] {
  margin: 0;
  width: auto;
}

.top-contact-heading {
  height: 450px;
  display: flex;
  align-items: center;
  padding-left: 6%;
  padding-top: 8%;
  font-family: "Cormorant Garamond", serif;
  border-bottom: 1px solid #e2e1e1;

  & h1 {
    font-size: clamp(20px, 50px, 70px);
  }
}

.contactform-containr {
  margin: 10% auto;
  min-width: 9rem;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.contactform-text {
  width: 60%;
  font-size: 15px;
  padding-bottom: 40px;
  border-bottom: 1px solid #e2e1e1;
}

p {
  font-size: 13px;
}

form {
  width: 60%;
  margin-top: 50px;
  margin-bottom: 100px;
}

textarea {
  height: 25rem;
}

a {
  color: #000;
}

.nextbutton {
  display: block;
  width: 30%;
  height: 80px;
  margin: auto;
  font-size: 14px;
  color: #fff;
  background-color: #00417f;
  margin-top: 40px;
}

.nextbutton:hover {
  background-color: #00274d;
}

.birthdate {
  width: 15vw;
}

.birthdate-ymd {
  margin-left: 0.5rem;
  margin-right: 0.5rem;
  font-size: 20px;
}

.postcode {
  width: 20vw;
  margin-bottom: 5px;
}

.post {
  margin-right: 0.5rem;
  font-size: 20px;
}

.qualification {
  height: 10rem;
}

.file-attachment::v-deep(.form-item-name) {
  width: 100%;
}

.file-attachment input {
  margin-left: 20%;
}

@media screen and (max-width: 1000px) {
  .contactform-text {
    width: 80%;
  }

  form {
    width: 80%;
  }
  .textbox {
    width: 100%;
  }
  .nextbutton {
    width: 250px;
  }

  .birthdate,
  .postcode {
    width: 50%;
  }

  .file-attachment input {
    margin-top: 15px;
  }
}
@media screen and (max-width: 600px) {
  .contactform-text {
    width: 80%;
    font-size: 15px;
  }
}
</style>
