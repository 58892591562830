<template>
  <div class="card-with-circle-icon">
    <h5>{{ title }}</h5>
    <img :src="imageUrl" />
    <h6>{{ subtitle }}</h6>
    <p>{{ description }}</p>
  </div>
</template>

<script>
export default {
  name: "CardWithCircleIcon",
  props: {
    imageUrl: {
      type: String,
      required: true,
    },
    title: {
      type: String,
      required: true,
    },
    subtitle: {
      type: String,
      required: true,
    },
    description: {
      type: String,
      required: true,
    },
  },
};
</script>

<style scoped>
.card-with-circle-icon {
  width: 500px;
  height: 600px;
  margin: 10px;
  background-color: #00417f;
  color: #fff;
}

.card-with-circle-icon h5 {
  font-family: "Cormorant Garamond", serif;
  margin-top: 80px;
  margin-bottom: 60px;
  font-size: 22px;
  font-weight: bold;
  text-align: center;
}

.card-with-circle-icon h6 {
  text-align: center;
  margin-top: 50px;
}

.card-with-circle-icon img {
  width: 30%;
  padding: 30px;
  margin: auto;
  background-color: #ebebec;
  clip-path: circle(50%);
  display: block;
}

.card-with-circle-icon p {
  margin: 30px 60px;
  font-size: 13px;
}
</style>
