<template>
  <div class="news-container" id="news-section">
    <div class="break-section">
      <div class="break-image"></div>
    </div>
    <div class="section news d-flex align-items-center justify-content-center">
      <div class="heading-news text-center">
        <h2 class="my-5">News</h2>
        <p class="news-more">
          <a href="#/comingsoon" class="news-more-link"
            >ニュース一覧
            <i class="bi bi-arrow-right ms-2"></i>
          </a>
        </p>
      </div>
      <NewsList :news-list="newsList" />
    </div>
  </div>
</template>

<script>
import NewsList from "@/components/molecules/NewsList.vue";
import newsList from "@/assets/news-list.json";

export default {
  name: "NewsSection",
  components: {
    NewsList,
  },
  data() {
    return {
      newsList: newsList,
    };
  },
};
</script>

<style scoped>
.break-section {
  position: relative;
  margin: 0 auto;
  width: 100%;
}

.break-image {
  background-image: url("@/static/breakimage.jpg");
  width: 80%;
  height: 1500px;
  background-position: center;
  background-size: cover;
  background-attachment: fixed;
}

.news {
  position: relative;
  top: -800px;
}

.heading-news {
  margin: auto;
  & h2 {
    color: #fff;
    font-size: 60px;
  }
}

.news-more {
  width: 170px;
  height: 45px;
  padding: 5px;
  background-color: #fff;
  border-radius: 2em;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #fff;
  transition: color 0.2s, background-color 0.2s;
  & i {
    transition: all 1s;
  }
}

.news-more:hover {
  background-color: #000;
  border: 1px solid #000;
  & .news-more-link {
    color: #fff;
  }
  & i {
    transform: translateX(10px);
  }
}

.news-more-link {
  border-radius: 2em;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #000;
  font-size: 16px;
}

@media screen and (max-width: 1000px) {
  .break-image {
    width: 100%;
    height: 110vh;
    background-position: center;
    background-size: cover;
    background-attachment: fixed;
  }
  @media screen and (max-width: 800px) {
    .news-container {
      height: 140vh;
    }
    .section {
      display: flex;
      flex-direction: column;
    }

    .heading-news {
      margin-bottom: 30px;
    }
  }
}
</style>
