<template>
  <div>
    <div class="information-security-heading">
      <h1>Certification and Accreditation</h1>
    </div>
    <img
      class="information-security-img"
      src="@/static/Certification-Accreditation.jpg"
      alt=""
    />

    <div class="information-security-strategy">
      <h2>Strategy</h2>
      <div class="strategy-little-explanation">
        <div>
          <b>「第三者認証取得支援」 </b>
          <p>
            第三者認証取得のプロセスを全面的にサポートし、継続的な改善と成長を支援。組織全体のレベルアップを実現します。
          </p>
        </div>
      </div>
    </div>
    <div class="security-container">
      <CardWithCircleIcon
        :imageUrl="require('@/static/engagement.png')"
        title="Trust"
        subtitle="信頼性を証明し、企業価値を向上"
        description="企業が市場での競争力を高め、顧客やパートナーとの信頼関係を築くために、必要な第三者認証の取得を支援します。"
      />
      <CardWithCircleIcon
        :imageUrl="require('@/static/idea.png')"
        title="Performance"
        subtitle="実績と経験に基づく確実なサポート"
        description="企業の現状と目指すべき方向性に応じて、認証取得に向けた確実なプランを策定し、並走的サポートを行います。"
      />
      <CardWithCircleIcon
        :imageUrl="require('@/static/value-chain.png')"
        title="Persistence"
        subtitle="認証取得後の継続的改善"
        description="認証を取得した企業がその後も基準を満たし続けるための改善活動を支援し、定期的な監査や運用体制の見直しを提案します。"
      />
    </div>

    <div class="servicemenu-container">
      <div>
        <h2>Service Menu</h2>
      </div>
      <div class="service-menu-box">
        <div class="service-item">
          <p>プライバシーマーク</p>
        </div>

        <div class="service-item">
          <p>ISO27001（ISMS）</p>
        </div>
      </div>
      <div class="service-menu-box">
        <div class="service-item">
          <p>TISAX認証</p>
        </div>

        <div class="service-item">
          <p>ISO9001・14001</p>
        </div>
      </div>
      <div class="service-menu-box">
        <div class="service-item">
          <p>ISMAP登録</p>
        </div>

        <div class="service-item">
          <p>PCI-DSS準拠</p>
        </div>
      </div>
    </div>

    <div class="srviceslink">
      <button class="informationsecurityservice-url">
        <a href="#/InformationSecurityService">
          <h3 class="service-panel-title">Information Security</h3>
          <p class="service-panel-subtitle">
            リスクマネジメントコンサルティング
          </p>
          <p class="service-panel-link">
            View More <i class="bi bi-arrow-right mx-3"></i>
          </p>
        </a>
      </button>
      <button class="riskmanagementservice-url">
        <a href="#/RiskManagementService">
          <h3 class="service-panel-title">Risk Management</h3>
          <p class="service-panel-subtitle">
            リスクマネジメントコンサルティング
          </p>
          <p class="service-panel-link">
            View More <i class="bi bi-arrow-right mx-3"></i>
          </p>
        </a>
      </button>
    </div>
    <ContactSection />
  </div>
</template>

<script>
import ContactSection from "@/components/organisms/ContactSection.vue";
import CardWithCircleIcon from "@/components/atoms/CardWithCircleIcon.vue";
export default {
  name: "ServicePage",
  components: { ContactSection, CardWithCircleIcon },
};
</script>

<style scoped>
.information-security-heading {
  height: 450px;
  display: flex;
  align-items: center;
  padding-left: 6%;
  padding-top: 8%;
  font-family: "Cormorant Garamond", serif;

  & h1 {
    font-size: clamp(20px, 50px, 70px);
  }
}

.service-panel-title {
  height: 70px;
}

.information-security-img {
  width: 100%;
  height: 800px;
  aspect-ratio: 16 / 7;
  background-size: cover;
  object-fit: cover;
  display: block;
}

.information-security-strategy {
  margin-top: 200px;
  margin-bottom: 150px;
  display: flex;
  justify-content: center;
}

.information-security-strategy h2 {
  font-size: 50px;
  margin: auto 10%;
}

.strategy-little-explanation {
  margin: auto 10%;
  & p {
    font-size: 13px;
  }
}

.security-container {
  margin-bottom: 250px;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
}

.servicemenu-container {
  height: 910px;
  margin-bottom: 300px;
  background-color: aliceblue;

  & h2 {
    font-size: 50px;
    margin-left: 8%;
    margin-bottom: 100px;
    padding-top: 150px;
  }
}

.service-menu-box {
  display: flex;
  justify-content: space-around;
  margin-bottom: 70px;
}

.service-item {
  width: 35%;
  height: 100px;
  background-color: #00417f;

  color: #fff;
  font-size: 20px;
  padding-left: 15px;
  line-height: 100px;
}

.informationsecurityservice-url,
.riskmanagementservice-url {
  width: 50%;
  height: 500px;
  background-size: cover;
  object-fit: cover;
  border: none;

  & a {
    color: #fff;
  }
}

.informationsecurityservice-url {
  background-image: url(@/static/Information-Security.jpg);
}

.riskmanagementservice-url {
  background-image: url(@/static/Risk-Management-short.jpg);
}

.service-panel-link {
  margin-top: 10%;
  font-size: 80%;
  font-family: "Cormorant Garamond", serif;
  font-weight: 100;
  display: flex;
  margin-left: 43%;
  font-size: 15px;
}

.service-panel-link i {
  transition: transform 0.4s ease; /* 矢印アイコンの遷移設定 */
}

.informationsecurityservice-url:hover .service-panel-link i,
.riskmanagementservice-url:hover .service-panel-link i {
  transform: translateX(5px); /* 矢印アイコンを5px右へ移動 */
}

@media screen and (max-width: 1000px) {
  .information-security-strategy {
    flex-direction: column;
  }

  .information-security-strategy h2 {
    font-size: 50px;
    margin-bottom: 20px;
  }

  .servicemenu-container {
    height: 1050px;
    margin-bottom: 300px;
  }

  .service-menu-box {
    flex-direction: column;
    margin-bottom: 0px;
  }
  .service-item {
    width: 55%;
    margin-left: 10%;
    margin-bottom: 30px;
  }

  .informationsecurityservice-url,
  .riskmanagementservice-url {
    height: 300px;
  }
}
@media screen and (max-width: 600px) {
  .service-item {
    width: 65%;
    height: 80px;
    font-size: 15px;
    line-height: 80px;
  }
}
</style>
