<template>
  <div
    class="service-panel"
    @mouseenter="servicePanelMouseHandler(panelId)"
    @mouseleave="servicePanelMouseHandler(panelId)"
  >
    <img :src="backgroundImage" :id="`panel-bg-${panelId}`" />
    <img
      :src="transitionImage"
      :id="`panel-transition-${panelId}`"
      class="fadeout"
    />
    <button class="service-panel-content">
      <a :href="url">
        <h3>{{ mainTitle }}</h3>
        <p class="service-panel-subtitle">{{ subTitle }}</p>
        <p class="service-panel-link">
          View More <i class="bi bi-arrow-right mx-3"></i>
        </p>
      </a>
    </button>
  </div>
</template>

<script>
export default {
  name: "ServicePanel",
  props: {
    panelId: {
      type: String,
      required: true,
    },
    backgroundImage: {
      type: String,
      required: true,
    },
    transitionImage: {
      type: String,
      required: true,
    },
    url: {
      type: String,
      required: true,
    },
    mainTitle: {
      type: String,
      required: true,
    },
    subTitle: {
      type: String,
      required: true,
    },
  },
  methods: {
    servicePanelMouseHandler: (id) => {
      const bgId = "panel-bg-" + id;
      const trId = "panel-transition-" + id;

      const bgEl = document.getElementById(bgId);
      if (bgEl.classList.contains("fadeout")) {
        bgEl.classList.remove("fadeout");
        bgEl.classList.add("fadein");
      } else {
        bgEl.classList.remove("fadein");
        bgEl.classList.add("fadeout");
      }

      const trEl = document.getElementById(trId);
      if (trEl.classList.contains("fadeout")) {
        trEl.classList.remove("fadeout");
        trEl.classList.add("fadein");
      } else {
        trEl.classList.remove("fadein");
        trEl.classList.add("fadeout");
      }
    },
  },
};
</script>

<style scoped>
.service-panel {
  width: calc(100% / 3);
  height: 60vw;
  max-height: 85vh;
  position: relative;
}

.service-panel img {
  position: absolute;
  height: 100%;
  width: 100%;
  z-index: -1;
}

img[id*="panel-transition"] {
  filter: brightness(50%);
}

.fadein {
  opacity: 1 !important;
  transition: opacity 2.2s;
}

.fadeout {
  opacity: 0 !important;
  transition: opacity 2s;
}

.service-panel-content {
  background: rgba(0, 0, 0, 0);
  width: 100%;
  height: 100%;
  border: none;
  flex-basis: 0;
  flex-grow: 1;
  flex-shrink: 1;
}

.service-panel-content a {
  color: #fff;
}

.service-panel-subtitle {
  font-size: 19px;
  font-weight: 100;
}

.service-panel-link {
  margin-top: 10%;
  font-family: "Cormorant Garamond", serif;
  font-weight: 100;
  display: flex;
  margin-left: 43%;
  font-size: 15px;
}

.service-panel-link i {
  transition: transform 0.4s ease; /* 矢印アイコンの遷移設定 */
}

.service-panel:hover .service-panel-link i {
  transform: translateX(5px); /* 矢印アイコンを5px右へ移動 */
}

@media screen and (max-width: 1000px) {
  .service-panel-content h3 {
    font-size: 20px;
  }

  .service-panel-subtitle {
    font-size: 11px;
  }
  .service-panel-link {
    font-size: 13px;
  }
}
@media screen and (max-width: 550px) {
  .service-panel-content h3 {
    font-size: 13px;
  }

  .service-panel-subtitle {
    font-size: 7px;
  }
  .service-panel-link {
    font-size: 6px;
  }
}
</style>
