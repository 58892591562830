<template>
  <div class="section recruit">
    <div
      class="recruit-container d-flex align-items-center justify-content-around"
    >
      <div class="heading-recruit">
        <h1>Recruit</h1>
        <p>
          We support your success to transform society by those individuals.
        </p>
      </div>
      <div class="me-5">
        <ViewMoreButton link="#/Recruit" />
      </div>
    </div>
  </div>
</template>

<script>
import ViewMoreButton from "../atoms/ViewMoreButton.vue";
export default {
  name: "RecruitSection",
  components: {
    ViewMoreButton,
  },
};
</script>

<style scoped>
.recruit {
  width: 85%;
  height: 450px;
  position: relative;
  top: -60px;
  margin-bottom: -20px;
  background-color: #fff;
}

.recruit-container {
  padding-top: 130px;
}

.heading-recruit > p {
  font-family: "Cormorant Garamond", serif;
  font-size: 13px;
}
@media screen and (max-width: 1000px) {
  .recruit-container {
    padding-top: 50px;
    margin-left: 5%;
    display: flex;
    flex-direction: column;
  }
  .me-5 {
    margin-top: 10px;
    margin: auto;
  }
}
</style>
