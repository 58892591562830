<template>
  <div>
    <div class="top-antisocialpolicy-heading">
      <h1>反社会的勢力に対する基本方針</h1>
    </div>
    <div class="antisocialpolicy-container">
      <div class="date-enactment-antisocialpolicy">
        <p>制定日 2017年7月1日</p>
        <p>最終改正日 2025年1月10日</p>
      </div>
      <div class="antisocialpolicy-box">
        <p>
          株式会社UPF（以下、「当社」という。）は、社会的責任ある企業として、暴力団を始めとする反社会的勢力による被害を防止するため、
          反社会的勢力に対する基本方針を以下のとおり定めるとともに、この基本方針を実現するための体制を構築します。
        </p>
        <p>1. 当社は、反社会的勢力とは、取引を含めた一切の関係を遮断します。</p>
        <p>
          2.
          当社は、反社会的勢力からの不当な要求に対して、組織全体で対応し、対応する社員の安全確保に努めます。
        </p>
        <p>
          3.
          当社は、反社会的勢力の不当要求には一切応じず、法に基づいて毅然と対応します。また、事案の隠蔽を目的とした裏取引は一切行いません。
        </p>
        <p>4. 当社は、反社会的勢力に対する資金提供は、絶対に行いません。</p>
      </div>
      <div class="president">
        <p>株式会社UPF 代表取締役 仲手川 啓</p>
        <p>以上</p>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "AntiSocialPolicyPage",
  components: {},
};
</script>

<style scoped>
h1 {
  height: 350px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 40px;
  text-align: center;
}

.antisocialpolicy-container {
  width: 60%;
  margin: auto;
  margin-bottom: 200px;
}

.antisocialpolicy-box {
  margin: 70px 0;

  & p {
    margin-bottom: 30px;
  }
}

.date-enactment-antisocialpolicy,
.president {
  text-align: right;
}
</style>
