<template>
  <div class="section service-detail">
    <div class="heading-service">
      <h2>Service</h2>
      <a href="#/servicelist">
        <span class="service-more-button d-flex justify-content-between">
          <p class="service-more ms-4">View More</p>
          <i class="bi bi-arrow-right mx-3"></i>
        </span>
      </a>
    </div>
    <SimpleSwiper>
      <div
        class="swiper-slide"
        v-for="(service, index) in services"
        :key="index"
      >
        <CardWithThumb
          :title="service.title"
          :image-url="service.imageUrl"
          :description="service.description"
        >
          <RoundedButton :url="service.url" :text="'サービスサイトへ'" />
        </CardWithThumb>
      </div>
    </SimpleSwiper>
  </div>
</template>

<script>
import servicesData from "@/assets/services.json";
import RoundedButton from "@/components/atoms/RoundedButton.vue";
import SimpleSwiper from "@/components/molecules/SimpleSwiper.vue";
import CardWithThumb from "@/components/atoms/CardWithThumb.vue";

export default {
  name: "ServiceSection",
  components: {
    RoundedButton,
    SimpleSwiper,
    CardWithThumb,
  },
  data() {
    return {
      services: servicesData,
    };
  },
};
</script>

<style scoped>
.service-detail {
  height: 1000px;
}

.heading-service {
  margin-top: 20%;
  margin-left: 10%;
  float: left;
  text-align: center;
}

.heading-service h2 {
  font-size: 60px;
  margin-bottom: 40px;
}

.service-more-button {
  font-family: "Cormorant Garamond", serif;

  width: 280px;
  height: 70px;
  line-height: 70px;
  font-size: 14px;
  color: #fff;
  background-color: #00417f;
  position: relative;
  display: inline-block;
  transition: color 0.25s ease;
  border: 1px solid #00417f;
  z-index: 1;

  &:hover {
    color: #00417f;
    &::before {
      transform: scaleX(1);
      transform-origin: left;
    }
  }

  &::before {
    position: absolute;
    top: 0;
    left: 0;
    content: "";
    display: block;
    width: 100%;
    height: 100%;
    background-color: #fff;
    transform: scaleX(0);
    transform-origin: right;
    transition: all 0.5s ease;
    transition-property: transform;
    z-index: -1;
  }
}

button:hover {
  transition: background-image 1s ease-in-out;
}

@media screen and (max-width: 1650px) {
  .heading-service {
    width: 90vw;
    margin-top: 60px;
    margin-left: 10%;
    display: flex;
    align-items: center;
  }

  .heading-service h2 {
    margin-right: 15%;
    margin-top: 35px;
    font-size: 50px;
  }

  .service-more-button {
    margin: 0;
  }
}

@media screen and (max-width: 600px) {
  .service-detail {
    height: 1200px;
  }
  .service-more-button {
    width: 280px;
    margin-bottom: 50px;
  }
  .heading-service {
    flex-direction: column;
  }
}
</style>
