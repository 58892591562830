<template>
  <div>
    <p class="error" v-if="error">
      {{ error }}
    </p>
    <div class="form-item">
      <div class="d-flex align-items-center">
        <label class="form-item-name">{{ name }}</label>
        <div v-if="required" class="required-box"><b>必須</b></div>
      </div>

      <slot></slot>
    </div>
  </div>
</template>

<script>
export default {
  name: "FormItem",
  props: {
    name: {
      type: String,
      required: true,
    },
    required: {
      type: Boolean,
      required: false,
    },
    error: {
      type: String,
    },
  },
};
</script>

<style scoped>
.error {
  color: #f00;
}

.form-item-name {
  font-size: 14px;
  font-weight: bold;
  width: 15vw;
}

.form-item {
  display: flex;
  margin-bottom: 2rem;
}

.required-box {
  width: 80px;
  height: 30px;
  color: #fff;
  font-size: 14px;
  padding: 5px;
  border: 1px solid #0366c2;
  background-color: #0366c2;
  margin-right: 20px;
  text-align: center;
}

@media screen and (max-width: 1000px) {
  .form-item {
    display: block;
  }
  .form-item-name {
    width: 26vw;
  }

  .required-box {
    margin: 10px 0;
    font-size: 13px;
    padding: 4px;
  }
}

@media screen and (max-width: 600px) {
  .form-item-name {
    width: 35vw;
  }
}
</style>
