<template>
  <div class="section company">
    <h2 class="heading-company">Company</h2>
    <div class="company-container">
      <p class="company-beginning">
        「お客様の安心と信頼を支えるパートナーへ」
      </p>
      <p class="company-beginning-introduction">
        弊社UPFは、これまでに培った実績とノウハウを活かし、お客様の安心と安全を支えるためのサービスを提供しています。<br />
        多様な課題に柔軟に対応し、信頼されるパートナーとして寄り添いながら、個々のニーズに合わせた最適なソリューションを提案します。<br />
        お客様と共に成長し続ける企業でありたいと考えています。
      </p>
      <div class="view-more-link">
        <ViewMoreButton link="#/company" />
      </div>
    </div>
  </div>
</template>

<script>
import ViewMoreButton from "../atoms/ViewMoreButton.vue";
export default {
  name: "CompanySection",
  components: {
    ViewMoreButton,
  },
};
</script>

<style scoped>
.company {
  height: 600px;
  display: flex;
  justify-content: space-around;
  align-items: center;
  font-size: 16px;
}

.company-container {
  width: 52%;
}

.company-more-link {
  width: 100px;
  border: 1px solid #000;
}

.view-more-link {
  width: 0px;
  height: 0px;
  margin-top: 30px;
}

.company-beginning {
  font-size: 20px;
  font-weight: bold;
  margin-bottom: 40px;
}

.company-beginning-introduction {
  font-size: 15px;
}

@media screen and (max-width: 1000px) {
  .company {
    display: block;
  }

  .heading-company {
    margin-top: 90px;
    margin-bottom: 40px;
    margin-left: 10%;
  }

  .company-container {
    width: 80%;
    margin: 0 auto;
  }
}

@media screen and (max-width: 600px) {
  .company-beginning {
    font-size: 17px;
  }

  .company-beginning-introduction {
    font-size: 13px;
  }
}
</style>
