<template>
  <div
    class="section service-overview d-flex justify-content-center align-items-center"
  >
    <div class="d-flex align-items-center" style="width: 100%">
      <ServicePanel
        :panel-id="'01'"
        :background-image="require('@/static/service-top_01.jpg')"
        :transition-image="require('@/static/service2-1.jpg')"
        :url="'#/InformationSecurityService'"
        :main-title="'Information Security'"
        :sub-title="'情報セキュリティコンサルティング'"
      />
      <ServicePanel
        :panel-id="'02'"
        :background-image="require('@/static/service-top_02.jpg')"
        :transition-image="require('@/static/service2-2.jpg')"
        :url="'#/RiskManagementService'"
        :main-title="'Risk Management'"
        :sub-title="'リスクマネジメントコンサルティング'"
      />
      <ServicePanel
        :panel-id="'03'"
        :background-image="require('@/static/service-top_03.jpg')"
        :transition-image="require('@/static/service2-3.jpg')"
        :url="'#/CertificationAccreditation'"
        :main-title="'Certification and Accreditation'"
        :sub-title="'第三者認証取得支援'"
      />
    </div>
  </div>
</template>

<script>
import ServicePanel from "@/components/atoms/ServicePanel.vue";

export default {
  name: "ConsultingSection",
  components: {
    ServicePanel,
  },
};
</script>

<style scoped>
.service-overview {
  width: 100%;
  background-size: cover;
  object-fit: cover;
  display: block;
}

/* flex-basis: 0;
    flex-grow: 1;
    flex-shrink: 1; */
</style>
