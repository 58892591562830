<template>
  <div>
    <div class="top-interviewee-heading">
      <img src="@/static/peopleB.png" alt="女性のシルエット" />
      <div class="interviewee-beginning">
        <h6>コンサルタント事業部 コンサルタント</h6>
        <h6>M.I/2022年新卒入社</h6>
        <h3>自分のペースで成長できる環境、ワークライフバランスも大切に</h3>
      </div>
    </div>
    <div class="interview-container">
      <div class="interview-box">
        <h4><span>Q1. </span>入社を決めた理由を教えてください。</h4>
        <h5>社員の実現したい働き方を尊重してくれる</h5>
        <p>
          入社を決めた理由は、UPFが社員の実現したい働き方を尊重してくれる点に非常に魅力を感じたからです。<br />
          自分のライフスタイルに合わせて柔軟に働ける環境が整っているので、<br />
          プライベートも大切にしながら仕事ができるという点に安心感を覚えました。<br />
          その上で、情報セキュリティ業界は今後もニーズが高まり続ける分野であり、安定して働ける業界だと考えました。<br />
          この両方の要素が揃っていたことで、UPFで自分のキャリアを積んでいけると確信し、入社を決めました。
        </p>
      </div>
      <div class="interview-box">
        <h4><span>Q2.</span> 現在どのようなお仕事をされていますか？</h4>
        <h5>セキュリティ認証を取得するために必要なプロセスをサポート</h5>
        <p>
          私は主に情報セキュリティに関する第三者認証（Pマーク、ISMSなど）の取得支援を行っています。<br />
          特にPマークやISMS認証を得意としており、企業の規模に関わらず、企業がセキュリティ認証を取得するために必要なプロセスをサポートしています。<br />
          加えて、認証取得を目指してお客様と協力し、認証後の運用や改善提案まで幅広くサポートしています。<br />
          私の特徴として、案件数を年50件～80件にコントロールしながら、プライベートと仕事のバランスを重視しています。<br />
          無理なく、効率的に業務を進めることで、充実したライフスタイルを保ちながら、結果を出すことができます。<br />
          日々新しい案件に触れる中で、情報セキュリティの知識も深まり、業務の幅が広がっていくのを実感しています。
        </p>
      </div>
      <div class="interview-box">
        <h4><span>Q3.</span> やりがいを感じるのはどんなときですか？</h4>
        <h5>
          セキュリティ意識が高まり続けるのを見守ることが大きなやりがいです
        </h5>
        <p>
          やりがいを感じる瞬間は、お客様が無事に認証を取得し、その後の運用がスムーズに進んでいることを確認したときです。<br />
          特に、最初は社内の情報セキュリティ体制に不安を抱えている企業が、私のサポートで自信を持って認証を取得できるようになり、<br />
          その後もセキュリティ意識が高まり続けるのを見守ることが大きなやりがいです。
        </p>
      </div>
      <div class="interview-box">
        <h4><span>Q4. </span>UPFの魅力について教えてください。</h4>
        <h5>プライベートの充実と仕事の両立が可能な環境が整っている</h5>
        <p>
          「UPFの最大の魅力は、働き方の自由度と、成果を出せばしっかりと評価される点です。<br />
          特に、私のようにプライベートの時間も大切にしたい人にとっては、案件数を自分のペースで調整できることがとても魅力的です。<br />
          働く時間を自分でコントロールできるので、無理なく仕事に集中でき、生活の質を高めることができます。<br />
          さらに、手当や福利厚生も充実していて、社員一人ひとりの働き方やライフスタイルに合った支援が受けられる点が素晴らしいと思います。<br />
          自分のペースで働きながら、しっかり成果を上げていけるので、プライベートの充実と仕事の両立が可能な環境が整っています。
        </p>
      </div>
      <div class="interview-box">
        <h4>
          <span>Q5.</span> 応募を考えている方へのメッセージをお願いします！
        </h4>
        <h5>少しでも興味があれば、ぜひ一度この環境を体験してみてください！</h5>
        <p>
          UPFは、自由な働き方を重視する会社です。<br />
          成果を出しながらもワークライフバランスを大切にしたい方にとっては、非常に理想的な職場だと思います。<br />
          自分のペースで仕事を進め、評価される環境が整っているので、モチベーション高く働けると思います。<br />
          また、情報セキュリティ分野に興味がある方や、成長したいと考えている方には最適な場所です。<br />
          自分の得意分野でスキルを高め、実力を発揮できるチャンスがたくさんあります。<br />
          もし少しでも興味があれば、ぜひ一度この環境を体験してみてください！
        </p>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "IntervieweeMIPage",
  components: {},
};
</script>

<style scoped>
.top-interviewee-heading {
  padding-top: 200px;
  padding-bottom: 100px;
  display: flex;
  background-color: #f4faff;
  box-shadow: 0px -2px 10px 0px rgb(129, 171, 172);
}

.top-interviewee-heading img {
  width: 600px;
  height: 600px;
  margin-left: 10%;
  margin-right: 5%;
  background-color: #fff;
  object-fit: cover;
  object-position: top;
  /* border: 1px solid #000; */
  border-radius: 30px;
  box-shadow: 0 0 8px rgb(129, 155, 172);
}

.interviewee-beginning {
  margin-top: 30px;

  & h6 {
    font-size: 20px;
  }

  & h3 {
    margin-top: 30px;
    font-size: 40px;
    font-weight: bold;
    color: #00417f;
  }
}

.interview-container {
  width: 70%;
  margin: auto;
  margin-bottom: 250px;
  margin-top: 150px;
}

.interview-box {
  margin-bottom: 80px;
  /* border: 1px solid #000; */
}

span {
  font-size: 40px;
  color: #00417f;
}

.interview-box h4 {
  padding-bottom: 40px;
  padding-left: 30px;
  font-size: 30px;
  font-weight: bold;
  border-bottom: 1px solid #a6c0d8;
}

.interview-box h5 {
  padding-left: 30px;
  margin-top: 30px;
  margin-bottom: 30px;
  font-size: 25px;
  font-weight: bold;
  color: #155591;
}

.interview-box p {
  padding-left: 30px;
  font-size: 18px;
}

@media screen and (max-width: 1400px) {
  .sp {
    display: none;
  }
}

@media screen and (max-width: 1000px) {
  .top-interviewee-heading {
    display: flex;
    flex-direction: column;
  }

  .top-interviewee-heading img {
    width: 80%;
    height: 500px;
  }

  .interviewee-beginning h6,
  .interviewee-beginning h3 {
    margin: auto;
    margin-top: 10px;
    width: 80%;
  }

  .interviewee-beginning h3 {
    font-size: 30px;
  }

  .interview-container {
    width: 90%;
  }
}

@media screen and (max-width: 700px) {
  .top-interviewee-heading img {
    height: 400px;
  }

  span {
    font-size: 30px;
  }

  .interview-box h4 {
    font-size: 25px;
  }

  .interview-box h5 {
    font-size: 20px;
  }

  .interview-box p {
    font-size: 15px;
  }
}
</style>
