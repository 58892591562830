<template>
  <div class="news-links-container">
    <div class="news-links d-flex flex-column">
      <NewsItem
        v-for="news in newsList"
        :key="news.id"
        :date="news.date"
        :title="news.title"
        :link="news.link"
      />
    </div>
  </div>
</template>

<script>
import NewsItem from "@/components/atoms/NewsItem.vue";

export default {
  name: "NewsList",
  props: {
    newsList: {
      type: Array,
      required: true,
    },
  },
  components: {
    NewsItem,
  },
};
</script>

<style scoped>
.news-links-container {
  margin: auto 0 100 auto;
  width: 60vw;
  background-color: #fff;
  padding: 10vh 0;
}

.news-links {
  width: 50vw;
  margin-left: 100px;
}

@media screen and (max-width: 1000px) {
  .news-links-container {
    width: 60vw;
  }
  .news-links {
    margin-left: 70px;
    width: 50vw;
  }
}

@media screen and (max-width: 800px) {
  .news-links-container {
    margin: auto;
    width: 80%;
  }
  .news-links {
    margin-left: 50px;
    width: 60vw;
  }
}
</style>
