<template>
  <div>
    <div class="top-contact-heading">
      <h1>新卒採用</h1>
    </div>
    <EntryForm />
  </div>
</template>

<script>
import EntryForm from "../organisms/EntryForm.vue";

export default {
  name: "NewGraduateHiringPage",
  components: {
    EntryForm,
  },
};
</script>

<style scoped>
.top-contact-heading {
  height: 450px;
  display: flex;
  align-items: center;
  padding-left: 6%;
  padding-top: 8%;
  font-family: "Cormorant Garamond", serif;
  border-bottom: 1px solid #e2e1e1;

  & h1 {
    font-size: clamp(20px, 50px, 70px);
  }
}
</style>
