<template>
  <div class="section d-flex contact align-items-center justify-content-around">
    <div class="heading-contact">
      <h1 class="">Contact</h1>
      <p>当社のサービスや採用に関するお問い合わせはこちらから</p>
    </div>

    <a href="#/Contact" class="form-contact d-flex align-items-center mx-5">
      <i class="mail-img bi bi-envelope m-4"></i>
      <p class="contact-form-text">Contact form</p>
    </a>
  </div>
</template>

<script>
export default {
  name: "ContactSection",
};
</script>

<style scoped>
h1 {
  font-size: 5vw;
}

p {
  font-size: 1vw;
}
.contact {
  background-image: url("@/static/contactimg.jpg");
  background-size: cover;
  position: relative;
  aspect-ratio: 2.6/1;
  max-height: 700px;
  width: 100%;
}

.heading-contact > h1 {
  color: #fff;
}

.contact::before {
  content: "";
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-color: rgba(1, 11, 37, 0.7);
}

.heading-contact {
  z-index: 2;
  color: #fff;
}

.form-contact {
  width: 300px;
  height: 80px;
  color: #fff;
  border: 1px solid #fff;
  z-index: 2;
  position: relative;
  display: inline-block;
  transition: color 0.25s ease;

  &:hover {
    color: #00274d;
    .mail-img {
      border-right: 1px solid #00274d;
    }
    &::before {
      transform: scaleX(1);
      transform-origin: left;
    }
  }

  &::before {
    position: absolute;
    top: 0;
    left: 0;
    content: "";
    display: block;
    width: 100%;
    height: 100%;
    background-color: #fff;
    transform: scaleX(0);
    transform-origin: right;
    transition: all 0.5s ease;
    transition-property: transform;
    z-index: -1;
  }
}

.mail-img {
  font-size: 22px;
  padding-left: 5%;
  padding-right: 15%;
  line-height: 1;
  border-right: 1px solid #fff;
  z-index: 1;
}

.contact-form-text {
  margin-top: 18px;
  margin-left: 18px;
  z-index: 2;
  font-family: "Cormorant Garamond", serif;
  text-align: center;
  font-size: 100%;
}
@media screen and (max-width: 600px) {
  .contact {
    display: none !important;
  }
}
</style>
