<template>
  <div class="top-news-heading">
    <h1>News</h1>
  </div>
</template>

<script>
export default {
  name: "NewsPage",
  components: {},
};
</script>

<style scoped>
.top-news-heading {
  height: 450px;
  display: flex;
  align-items: center;
  padding-left: 6%;
  padding-top: 8%;
  font-family: "Cormorant Garamond", serif;
  border-bottom: 1px solid #e2e1e1;

  & h1 {
    font-size: clamp(20px, 50px, 70px);
  }
}
</style>
